import { message } from 'ant-design-vue'

/**
 * 用于文本复制
 */
export const copyText = (
  text: string,
  successMsg = '复制成功',
  errMsg = '复制失败'
) => {
  try {
    // 检查 Clipboard API(clipboard只能在https环境中使用，且在某些低版本浏览器也无法使用)
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          message.success(successMsg)
        })
        .catch((err) => {
          console.log(errMsg, err)
          message.error(errMsg)
        })
    } else {
      // 回退方案(使用textarea保留换行符)
      const textarea = document.createElement('textarea')
      document.body.appendChild(textarea)
      textarea.value = text
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      message.success(successMsg)
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

/**
 * @description 数组根据步数进行内部值的移动
 * @param arr 数组
 * @param steps 移动步数
 * @returns
 */
export function rotateArray(arr: any[], steps: number) {
  if (!Array.isArray(arr)) arr = []
  if (typeof steps !== 'number') steps = 0
  const length = arr.length
  if (length === 0) return arr // 空数组直接返回
  // 计算实际移动步数，考虑负数和数组长度
  const actualSteps = ((steps % length) + length) % length
  return arr.slice(-actualSteps).concat(arr.slice(0, -actualSteps))
}

/**
 * @description 去除数组中相同的元素
 * @param arr1
 * @param arr2
 * @returns
 */
export function removeRepeat(arr1: any[], arr2: any[]) {
  return arr1.filter((it) => !arr2.includes(it))
}
