/** 为 fabric 创建的一系列控制方法 */
import { PointType, Rect } from '@/partTypes'
import { removeRepeat, rotateArray } from '@/util/common'
import { rectToPath } from '@/util/commonFuncs'
import { getRectangleBounds } from '@/util/graphCalc'
import { getPathMinMaxPoint } from '@/util/plankCommonFuncs'
import { fabric } from 'fabric'

/**
 * 注意事项
 * 1. 对于多选元素的 set 操作大部分都会直接映射到其子元素上，如不想讲多选元素的修改值传到子元素则需使用 el.set(key, value) 的方式去修改
 */

/** 触发移动吸附的阈值 */
const THRESHOLD = 1.5
/** 缓存类型 */
enum Caches {
  single = 'single',
  selected = 'multi',
}
type CacheType = `${Caches}`
type Options = {
  /** 缓存数量 */
  cacheNum: number
}

type IFabricObject = fabric.Object & {
  item_id?: string
  typeName?: string
  /** 是否是克隆出来的元素 */
  _isClone?: boolean
  /** 克隆元素克隆的 item_id */
  _clone_item_id?: string
  /** 是否是一条用于显示的线段 */
  _isLine?: boolean
  /** 缓存之前的透明度 */
  _afterOpacity?: number
  auxiliaryEl?: IFabricObject[]
  /** set 函数是否被重写过的标识 */
  _setFuncChange?: boolean
}

/**
 * @description 用于控制在fabric 元素多选时的相关功能
 */
export class FabricSelectedControl {
  public instanceMap = new WeakMap<fabric.Canvas, fabric.Canvas>()
  /** 配置项 */
  public options: Options = {
    /** 缓存数量 */
    cacheNum: 3,
  }
  /** 当前整在使用的一些数据 */
  public current = {
    /** 当前选区 */
    currentSelected: null as fabric.ActiveSelection | null,
  }
  event = new Map<
    'selectedUpdate' | 'selectedAttributeUpdate',
    ((...args: any) => void)[]
  >()
  /** 存储之前活跃过的元素组件,最后一个是当前活跃元素 */
  private _previousActiveObject = new WeakMap<
    fabric.Canvas,
    { type: CacheType; objects: IFabricObject[] }[]
  >()
  constructor(public canvas: fabric.Canvas, options?: Partial<Options>) {
    Object.assign(this.options, options)
    this.addEvent()
  }
  public on(
    eventName: 'selectedUpdate' | 'selectedAttributeUpdate',
    fn: (...args: any) => void
  ) {
    this.event.get(eventName)?.push(fn) || this.event.set(eventName, [fn])
  }
  public remove(eventName: 'selectedUpdate' | 'selectedAttributeUpdate') {
    this.event.delete(eventName)
  }
  public removeAll() {
    this.event.clear()
  }
  /** 存储当前实例的活跃对象 */
  public addActiveToPre(activeObjects?: IFabricObject[]) {
    const activeArr = this._previousActiveObject.get(this.canvas)
    const activeObjs = activeObjects || this.canvas.getActiveObjects()
    if (activeObjs.length === 0) return
    const type: CacheType = activeObjs.length === 1 ? 'single' : 'multi'
    if (!activeArr) {
      this._previousActiveObject.set(this.canvas, [
        { objects: activeObjs, type },
      ])
      return
    }
    // 当存储数量超过指定数量则删除最久未使用的那一组，避免过多的存储
    if (activeArr.length >= this.options.cacheNum) {
      activeArr.shift()
    }
    activeArr.push({ objects: activeObjs, type })
  }
  /** 获取当前实例存储的活跃对象 */
  public getPreActiveObject() {
    return this._previousActiveObject.get(this.canvas)
  }
  /** 清除当前实例存储的活跃对象 */
  public clearPreActiveObject(isDelete = false) {
    if (isDelete) {
      this._previousActiveObject.delete(this.canvas)
    } else {
      const arr = this.getPreActiveObject()
      // 只是清空不删除
      arr && (arr.length = 0)
    }
    return
  }
  /** 清除指定元素在缓存的对象中 */
  public clearStoreBeDeleteObject(object: IFabricObject) {
    // 当元素被删除时需要及时清理
    const preData = this.getPreActiveObject()
    preData?.forEach((pre) => {
      for (let idx = pre.objects.length - 1; idx >= 0; idx--) {
        if (pre.objects[idx] === object) {
          pre.objects.splice(idx, 1)
        }
      }
    })
  }
  /** 添加事件监听 */
  public addEvent() {
    this.canvas.on('mouse:down', (e: any) => {
      if (!e.target) return
      if (!isTageBoxEl(e.target) && e.button === 1) {
        // 记录当前活跃对象
        const objects = [
          ...(this.canvas?.getActiveObjects() ?? []),
        ] as IFabricObject[]
        objects.forEach((object) => {
          if (object.auxiliaryEl) {
            objects.push(...object.auxiliaryEl)
          }
        })
        this.addActiveToPre(objects)
      }
    })
    this.canvas.on('mouse:up', (e: any) => {
      if (e.button === 1) {
        if (!e.target) {
          this.clearPreActiveObject()
          return
        }
        if (isTageBoxEl(e.target)) {
          this.clearPreActiveObject()
        }
      }
    })
    this.canvas.on('selection:created', (e: any) => {
      if (!e.target) return
      const objects = e.selected
      // 当元素大于两个时才是创建了真正意义上的选区
      if (objects.length >= 2) {
        const typeNameArr = objects.map((it: IFabricObject) => it.typeName)
        // 如果为空的情况则使用Selected来创建，用于显示页面
        if (
          typeNameArr.length === 0 ||
          typeNameArr.some(
            (k: string) => !['DataSource', 'FixedText'].includes(k)
          )
        ) {
          typeNameArr.length === 0
          typeNameArr.push('Selected')
        }
        e.target.set({
          hasControls: false, // 关闭控制
          lockRotation: true,
          evented: true,
          selectable: true,
          // 因为要和普通的DataSource/FixedText区分开所以复制了一份
          typeName: [...typeNameArr, ...typeNameArr].join(','),
          _type: 'selection',
        } as any)
        dealObjectSetFunc(e.target, (object, options) => {
          this.event.get('selectedAttributeUpdate')?.forEach((fn) => {
            fn(object, options)
          })
        })
        this._setCurrent('currentSelected', e.target)
        // 此处只记录选择两个以上是因为点击单个元素时，选区和点击会有可能同时触发会存储两次
        if (!isTageBoxEl(e.target) && isSelectedEl(e.target)) {
          // 记录当前活跃对象
          this.addActiveToPre()
        }
      }
    })
    /** 监听元素的移出 */
    this.canvas.on('object:removed', (e) => {
      if (!e.target) return
      this.clearStoreBeDeleteObject(e.target)
    })
  }
  /** 添加一个元素到当前选区 */
  public addObjectToSelected(object: IFabricObject | IFabricObject[]) {
    if (!Array.isArray(object)) {
      if (!object) return
      if (isTageBoxEl(object)) return
    }
    const cacheObjects = this.getPreActiveObject()
    if (!cacheObjects) return
    // 取 0 的意义为：如之前只点击了单个则取最后一个， 如超过 2 个则倒数第二个是之前选中的对象才能正常的进行多选
    const selectedObjects = cacheObjects.slice(-2)[0]
    if (!selectedObjects?.objects?.length) return
    const objects = Array.isArray(object) ? object : [object]
    objects.forEach((item) => {
      if (item.auxiliaryEl) {
        objects.push(...item.auxiliaryEl)
      }
    })
    const nObjects = Array.from(
      new Set([
        // 需要同时去除相同的元素，可以实现再次点击相同的元素则是取消选中
        ...removeRepeat(selectedObjects?.objects, objects),
        ...removeRepeat(objects, selectedObjects?.objects),
      ])
    )
    // 单个元素不创建选区
    if (nObjects.length <= 1) return
    this.createNewSelected(nObjects)
  }
  /**
   * @description 创建一个新的选区
   * @param objects
   */
  public createNewSelected(objects: IFabricObject[]) {
    const typeNameArr = objects.map(
      (it: IFabricObject) => it.typeName
    ) as string[]
    // 如果为空的情况则使用Selected来创建，用于显示页面
    if (
      typeNameArr.length === 0 ||
      typeNameArr.some((k: string) => !['DataSource', 'FixedText'].includes(k))
    ) {
      typeNameArr.length === 0
      typeNameArr.push('Selected')
    }
    const newSelection = new fabric.ActiveSelection(objects, {
      canvas: this.canvas,
      hasControls: false,
      lockRotation: true,
      evented: false,
      selectable: false,
      // 因为要和普通的DataSource/FixedText区分开所以复制了一份
      typeName: [...typeNameArr, ...typeNameArr].join(','),
      _type: 'selection',
    } as unknown as IFabricObject)
    dealObjectSetFunc(newSelection, (object, options) => {
      this.event.get('selectedAttributeUpdate')?.forEach((fn) => {
        fn(object, options)
      })
    })
    this.canvas.setActiveObject(newSelection)
    // 将当前活跃的对象添加到历史记录中
    this.addActiveToPre(objects)
    // 记录当前的选区
    this._setCurrent('currentSelected', newSelection)
    this.canvas.renderAll()
  }
  /** 设置当前使用的对象 */
  private _setCurrent(key: 'currentSelected', val: any) {
    this.current[key] = val
    if (key === 'currentSelected') {
      this.event.get('selectedUpdate')?.forEach((fn) => fn(val))
    }
  }
}

/**
 * @description 用于控制在fabric 元素移动时的相关控制功能包括吸附及其其他功能
 */
export class FabricMovingControl {
  cloneObject: IFabricObject | null = null
  /** 存储点击clone 的源元素 */
  afterTargetObject?: IFabricObject
  protected mode = {
    /** 鼠标按在元素上 */
    isTouchElement: false,
    /** 鼠标按下 */
    isMouseDown: false,
  }
  /** 键盘状态 */
  protected keyboardStatus = {
    /** 是否按下了ctrl键 */
    isCtrlDown: false,
  }
  constructor(public canvas: fabric.Canvas) {
    this.addEvent()
  }
  private mouseDown(e: fabric.IEvent) {
    this.mode.isMouseDown = true
    if (!this._checkEvent(e)) return
    if (isTageBoxEl(e.target!)) return
    // 需要判断一下其选区元素是否是大于 1
    if (e.button === 1 && !isSelectedEl(e.target!)) {
      // 活跃元素大于两个不执行，但需要排除孔槽图的附属元素
      const actives = getActiveObjectByNotAssignField(this.canvas)
      if (actives.length >= 2) {
        return
      }
      if (this.canvas.getActiveObject()) {
        this.mode.isTouchElement = true
      }
      const { object: target } = this.getActiveIsControlRectRealEl(e.target!)
      // 点击前先将目标元素设置为透明
      target?.clone((nEl: IFabricObject) => {
        this.cloneObject = nEl
        nEl.set({
          opacity: target.opacity,
          angle: target.angle,
          scaleX: target.scaleX,
          scaleY: target.scaleY,
          _isClone: true,
          _clone_item_id: target.item_id,
        })
        this.canvas.add(nEl)
        target?.set({
          opacity: 0,
          // 存储之前的透明度
          _afterOpacity: target.opacity,
        })
      })
      this.afterTargetObject = target
      this.canvas.renderAll()
    }
  }
  private mouseUp(e: fabric.IEvent) {
    this.mode.isMouseDown = false
    // 鼠标抬起则删除线段
    if (e.button === 1) {
      // 删除线段绘制
      this._delRedDashLine()
      let object = this.afterTargetObject
      object?.set({
        opacity: object._afterOpacity ?? object.opacity,
      })
      if (this.mode.isTouchElement && this.cloneObject) {
        this.mode.isTouchElement = false
        const handleTarget = e.target as IFabricObject
        if (!handleTarget) return
        ;({ object } = this.getActiveIsControlRectRealEl(handleTarget))
        const left = this.cloneObject?.left ?? object?.left ?? 0
        const top = this.cloneObject?.top ?? object?.top ?? 0
        object?.set({ left, top })
        if (
          isControlRectEl(handleTarget) ||
          handleTarget.typeName === 'DataSource'
        ) {
          const controlRectTarget = this.canvas
            .getObjects()
            .find(
              (it: IFabricObject) =>
                isControlRectEl(it) && it.item_id === handleTarget.item_id
            )
          controlRectTarget?.set({ left: left - 1, top: top - 1 })
        }
        handleTarget?.set({
          opacity: handleTarget?._afterOpacity ?? handleTarget.opacity,
        })
        // 清除克隆元素
        this.clearClone()
        this.canvas.renderAll()
      }
    }
  }
  private mouseMove(e: fabric.IEvent) {
    if (!e.target) return
    if (isSelectedEl(e.target)) return
    // 如果按下了ctrl键，则不进行吸附
    if (this.keyboardStatus.isCtrlDown) return
    // 活跃元素大于两个不执行，但需要排除孔槽图的附属元素
    const actives = getActiveObjectByNotAssignField(this.canvas)
    if (actives.length >= 2) {
      return
    }
    // 不能对多选的元素进行移动 会有一些意想不到的 BUG
    if (this.mode.isTouchElement) {
      this._delRedDashLine()
      const target = e.target as IFabricObject
      const { x, y, lines } = this.getAdsorbNewPoint(
        target,
        this.canvas.getObjects().filter(
          (el: IFabricObject) =>
            // 限制条件 不能是 纸张元素/本身/控制元素所控制的元素
            !isTageBoxEl(el) &&
            !el._isClone &&
            el !== target &&
            !isControlRectEl(el) &&
            el.item_id !== target.item_id
        )
      )
      this._drawRedDashLine(lines)
      if (this.cloneObject) {
        const { width, height } = getElRealRect(target)
        const box = rectToPath({ x, y, width, height })
        // 由于旋转角度不同取值的位置不同, 逆时针取值
        let step =
          x !== target.left || y !== target.top
            ? ((target?.angle || 0) / 90) * -1
            : 0
        // 如果其原点之中心点则不做处理(没有心思去理解fabric 为什么旋转之后 left top 对应的原点也跟着旋转(麻了))
        // 这些相关的处理都只会针对原点是left top 且旋转角度是 90 的倍数的情况，如果其他旋转角度则建议将元素的原点设置为 center
        if (target.originX === 'center' && target.originY === 'center') {
          step = 0
        }
        const nArr = rotateArray(box, step)
        // 当旋转完成后只需要取第一个即可，第一个即是页面上看见的左上角起始位置
        const point = nArr[0]
        // 值一样的情况下不做任何改变
        point.x = x === target.left ? target.left : point.x
        point.y = y === target.top ? target.top : point.y
        setPositionByTopLeft(
          this.cloneObject,
          { left: point.x || 0, top: point.y || 0 },
          { left: target?.left || 0, top: target?.top || 0 }
        )
      }
    }
  }
  /** 改变按键状态 */
  keyboardStatusChange(key: 'ctrl', status: boolean) {
    switch (key) {
      case 'ctrl':
        this.keyboardStatus.isCtrlDown = status
        if (!this.mode.isMouseDown) {
          // ctrl 按下删除用于移动的克隆对象 但鼠标在按下移动期间不支持此操作
          status && this.clearClone()
        }
    }
  }
  /** 通用检查 */
  private _checkEvent(e: fabric.IEvent) {
    if (!e.target || isSelectedEl(e.target) || this.keyboardStatus.isCtrlDown) {
      return false
    }
    return true
  }
  /** 清除 clone 的元素 */
  protected clearClone() {
    if (this.cloneObject) {
      this.canvas.remove(this.cloneObject)
    }
    this.cloneObject = null
    this.afterTargetObject = void 0
  }
  /** 获取需要连线的位置点位 */
  private _getElLine(
    object1: Rect,
    object2: Rect,
    type: string
  ): PointType[][] {
    const { width, height, x, y } = object1
    const { width: width2, height: height2, x: x2, y: y2 } = object2
    const result: PointType[][] = []
    const addLine = (point1: PointType, point2: PointType) =>
      result.push([point1, point2])
    switch (type) {
      case 'left':
        addLine(
          { x, y: y > y2 ? y : y + height },
          { x: x2, y: y > y2 ? y2 + height2 : y2 }
        )
        break
      case 'right':
        addLine(
          { x: x + width, y: y > y2 ? y : y + height },
          { x: x + width, y: y > y2 ? y2 + height2 : y2 }
        )
        break
      case 'top':
        addLine(
          { x: x > x2 ? x : x + width, y },
          { x: x > x2 ? x2 + width2 : x2, y: y2 }
        )
        break
      case 'bottom':
        addLine(
          { x: x > x2 ? x : x + width, y: y + height },
          { x: x > x2 ? x2 + width2 : x2, y: y + height }
        )
        break
      case 'vc':
        addLine(
          { x: x > x2 ? x : x + width, y: y + height / 2 },
          { x: x > x2 ? x2 + width2 : x2, y: y + height / 2 }
        )
        break
      case 'hc':
        addLine(
          { x: x + width / 2, y: y > y2 ? y : y + height },
          { x: x + width / 2, y: y > y2 ? y2 + height2 : y2 }
        )
        break
    }

    return result
  }
  /** 获取指定元素去吸附其他元素的新坐标 */
  getAdsorbNewPoint(object: IFabricObject, objects: IFabricObject[]) {
    const objectRect = getElRealRect(object)
    const { top, left, right, bottom, verticalCenter, horizontalCenter } =
      getRectangleBounds(objectRect)
    const width = objectRect.width || 0
    const height = objectRect.height || 0
    const newPoint: { x: number | null; y: number | null } = {
      x: null,
      y: null,
    }
    const result: PointType[][] = []
    // 辅助函数：记录连线结果
    const addLine = (
      type: string,
      source: { x: number; y: number },
      target: { x: number; y: number },
      item: IFabricObject
    ) => {
      const itemRect = getElRealRect(item)
      result.push(
        ...this._getElLine(
          { ...source, width, height },
          {
            ...target,
            width: itemRect.width || 0,
            height: itemRect.height || 0,
          },
          type
        )
      )
    }
    for (const item of objects) {
      if (typeof newPoint.x === 'number' && typeof newPoint.y === 'number')
        break
      const {
        top: t2,
        left: l2,
        right: r2,
        bottom: b2,
        verticalCenter: v2,
        horizontalCenter: h2,
      } = getRectangleBounds(getElRealRect(item))

      // 生效连线和顺序相关，想要哪个先对齐就将其移动至前方执行
      if (!newPoint.y) {
        // 垂直居中对齐
        if (Math.abs(verticalCenter - v2) <= THRESHOLD) {
          newPoint.y = v2 - (bottom - top) / 2
          addLine(
            'vc',
            { x: left, y: newPoint.y },
            { x: l2, y: newPoint.y },
            item
          )
          continue
        }
        // 上边对齐
        if (Math.abs(top - t2) <= THRESHOLD) {
          newPoint.y = t2
          addLine(
            'top',
            { x: left, y: newPoint.y },
            { x: l2, y: newPoint.y },
            item
          )
          continue
        }
        // 下边对齐
        if (Math.abs(bottom - b2) <= THRESHOLD) {
          newPoint.y = b2 - (bottom - top)
          addLine(
            'bottom',
            { x: left, y: newPoint.y },
            { x: l2, y: newPoint.y },
            item
          )
          continue
        }
      }
      if (!newPoint.x) {
        // 水平居中对齐
        if (Math.abs(horizontalCenter - h2) <= THRESHOLD) {
          newPoint.x = h2 - (right - left) / 2
          addLine(
            'hc',
            { x: newPoint.x, y: top },
            { x: newPoint.x, y: t2 },
            item
          )
          continue
        }
        // 左边对齐
        if (Math.abs(left - l2) <= THRESHOLD) {
          newPoint.x = l2
          addLine(
            'left',
            { x: newPoint.x, y: top },
            { x: newPoint.x, y: t2 },
            item
          )
          continue
        }

        // 右边对齐
        if (Math.abs(right - r2) <= THRESHOLD) {
          newPoint.x = r2 - (right - left)
          addLine(
            'right',
            { x: newPoint.x, y: top },
            { x: newPoint.x, y: t2 },
            item
          )
          continue
        }
      }
    }

    return {
      x: newPoint.x || object.left,
      y: newPoint.y || object.top,
      lines: result,
    }
  }
  /** 获取传入的 元素是否是控制块，如果是控制块则找到其真正控制的元素 */
  getActiveIsControlRectRealEl(object: IFabricObject) {
    if (!isControlRectEl(object))
      return {
        object,
        controlRect: null,
      }
    const targetObject = (this.canvas.getObjects() as IFabricObject[]).find(
      (item) => {
        return !isControlRectEl(item) && item.item_id === object.item_id
      }
    )
    return {
      object: targetObject,
      controlRect: object,
    }
  }
  /**
   * @description 添加事件监听
   */
  addEvent() {
    this.canvas.on('mouse:down', (e) => this.mouseDown(e))
    this.canvas.on('mouse:up', (e) => this.mouseUp(e))
    this.canvas.on('mouse:move', (e) => this.mouseMove(e))
  }
  /**
   * @description 绘制红色虚线
   */
  private _drawRedDashLine(lines: PointType[][]) {
    lines?.forEach((line) => {
      const lineEl = new fabric.Line(
        [line[0].x, line[0].y, line[1].x, line[1].y],
        {
          stroke: '#f00',
          strokeWidth: 0.8,
          selectable: false,
          _isLine: true,
          strokeDashArray: [3, 1],
          opacity: 0.7,
        } as IFabricObject
      )
      this.canvas.add(lineEl)
    })
  }
  /**
   * @description 删除红色虚线
   */
  private _delRedDashLine() {
    this.canvas.getObjects().forEach((obj: IFabricObject) => {
      if (obj._isLine) {
        this.canvas.remove(obj)
      }
    })
  }
}

/**
 * @description 检查元素是否是纸张元素
 */
export function isTageBoxEl(el: IFabricObject) {
  return el.typeName === 'TageBox'
}

/**
 * @description 检查元素是否是控制元素
 */
export function isControlRectEl(el: IFabricObject) {
  return el.typeName === 'controlRect'
}

/**
 * @description 是否是选区元素
 */
export function isSelectedEl(el: IFabricObject) {
  return Reflect.get(el ?? {}, '_type') === 'selection'
}

/**
 * @description 是否是线段绘制
 */
export function isLineEl(el: IFabricObject) {
  return el._isLine
}

/**
 * @description 重写 fabric 元素对象的 set 方法，监听其改变并用于选区同步其子元素的改变
 */
export function dealObjectSetFunc(
  object: IFabricObject | fabric.ActiveSelection,
  cb?: (objects: IFabricObject[], options: any) => any
) {
  const originalSet = object.set as any
  // 如果函数被已经被重写过则不在重写
  if ((object as IFabricObject)._setFuncChange) {
    return
  }
  ;(object as IFabricObject).set({
    _setFuncChange: true,
  })
  object.set = function (this: any, option: any, value: any) {
    const result = originalSet.call(this, option, value)
    // 当key 本身是一个对象的时候，才需要去处理，因为我们页面内的操作都是用的 set 对象的方式去更改，所以需要禁止页面内使用 set(key, value) 的方式改值
    if (typeof option === 'object' && option) {
      // 需要忽略的 key，这些 key 的变动不要去同步，因为会影响页面显示和功能
      const ignoreKeys = [
        'evented',
        'selectable',
        'hasControls',
        'typeName',
        'item_id',
        '_type',
      ]
      Object.keys(option).forEach((k) => {
        if (ignoreKeys.includes(k)) {
          Reflect.deleteProperty(option, k)
        }
      })
      const nObjects = (object as any)?.getObjects()
      if (cb) {
        cb(nObjects, option)
      } else {
        nObjects?.forEach((object: any) => {
          // 因为有多种不同的存储子元素的方法所以需要都判断一下
          const objects = object.auxiliaryEl ||
            object.getObjects?.() || [object]
          objects.forEach((target: IFabricObject) => {
            target.set({ ...option })
          })
        })
      }
    }
    return result
  } as any
}

/**
 * @description 设置元素位置，根据其 origin 来设置位置, 目标是左上角
 */
export function setPositionByTopLeft(
  object: IFabricObject,
  position?: { left: number; top: number },
  oriPosition?: { left?: number; top?: number }
) {
  // 这只是这个功能的写法，不用太复杂，且目前只有一个元素用过 center 作为其原点，所以 这里就只处理 center 了
  const newPosition = {
    left: position?.left ?? object.left ?? 0,
    top: position?.top ?? object.top ?? 0,
  }
  const oldPosition = {
    left: oriPosition?.left ?? object.left ?? 0,
    top: oriPosition?.top ?? object.top ?? 0,
  }
  const origin = object.originX === 'center' ? 'center' : 'top-left'
  const { width, height } = getElRealRect(object)
  let top = newPosition.top,
    left = newPosition.left
  const getDiff = (dir: 'left' | 'top', newNum: number) => {
    return oldPosition[dir] !== newPosition[dir] ? newNum : newPosition[dir]
  }
  switch (origin) {
    case 'center':
      left = getDiff('left', newPosition.left + width / 2)
      top = getDiff('top', newPosition.top + height / 2)
      break
  }
  object.set({ left, top })
}

/**
 * @desc 获取元素的在画布的位置（没有选区的影响）
 * @param object 选区元素
 * @returns 位置信息
 */
export function getPositionByCanvas(object: IFabricObject) {
  const groupLeft = object.group?.left || 0
  const groupTop = object.group?.top || 0
  const groupWidth = object.group?.width || 0
  const groupHeight = object.group?.height || 0
  // 选区的情况下 内部元素是根据其中心点定位的
  const left = groupLeft + (groupWidth / 2 + (object?.left || 0))
  const top = groupTop + (groupHeight / 2 + (object?.top || 0))
  return { left, top }
}

/**
 * @description 获取元素真正的边框位置
 * @param el
 * @returns
 */
export function getElRealRect(el: IFabricObject) {
  const aCoords = el.calcCoords(true)
  const { tl, tr, bl, br } = aCoords
  const [minx, miny, maxx, maxy] = getPathMinMaxPoint([tl, tr, bl, br])
  return {
    x: minx,
    y: miny,
    width: maxx - minx,
    height: maxy - miny,
  }
}

/** 获取活跃元素但会排除一些指定的元素根据 typename */
export function getActiveObjectByNotAssignField(
  canvas: fabric.Canvas,
  keys = ['holeSlotPicture-size']
) {
  return canvas
    .getActiveObjects()
    ?.filter((it: IFabricObject) => !keys.includes(it.typeName + ''))
}
