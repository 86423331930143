/*
定义每种标签数据的type
FixedText  DataSource Graphics(图形) Typography(排版图) Onecode QRcode
 */
export function handleTagSaveData(saveData) {
  let items
  if (saveData.length > 1) {
    items = saveData.slice(1)
  } else {
    items = []
  }
  let req = []
  items.forEach((item) => {
    let reqItem = {
      type: item.typeName,
    }
    switch (item.typeName) {
      case 'FixedText':
        reqItem.data = getAttsValue(item)
        break
      case 'DataSource':
        reqItem.data = getAttsValue(item)
        break
      case 'Graphics':
        reqItem.data = getAttsValue(item)
        break
      case 'Typography':
        reqItem.data = handleTypographyData(item)
        break
      case 'Onecode':
        reqItem.data = handleOneCodeData(item)
        break
      case 'QRcode':
        reqItem.data = getAttsValue(item)
        break
      case 'wardrobeNum':
        reqItem.data = handleWardrobeNumData(item)
        break
      case 'specialMark':
        reqItem.data = getAttsValue(item)
        break
      case 'holeSlotPicture':
        reqItem.data = getAttsValue(item)
        break
      case 'holeSlotPicture-size':
        reqItem.data = getAttsValue(item)
        break
      case 'Table':
        reqItem.data = getAttsValue(item)
        break
      case 'Marking':
        reqItem.data = handleWardrobeNumData(item)
        break
      case 'SurplusSize':
        reqItem.data = handleTypographyData(item)
        break
    }
    req.push(reqItem)
  })
  return req
}
const needAtts = {
  QRcode: [
    'width',
    'height',
    'hasControls',
    'item_id',
    'left',
    'top',
    'source_data',
    'mHeight',
    'mWidth',
    'scaleX',
    'scaleY',
    'showCode',
    'isQRcodeNCSuffix',
    'QRCodeFormat',
  ],
  FixedText: [
    'text',
    'lineHeight',
    'fontSize',
    'fontWeight',
    'item_id',
    'fontPt',
    'hasControls',
    'left',
    'top',
    'splitByGrapheme',
    'width',
    'height',
    'textAlign',
    'fontFamily',
  ],
  DataSource: [
    'text',
    'lineHeight',
    'fontSize',
    'fontWeight',
    'item_id',
    'fontPt',
    'hasControls',
    'left',
    'top',
    'width',
    'height',
    'rectWidth',
    'rectHeight',
    'isAutoFontSize',
    'source_data',
    'textBackgroundColor',
    'isExtraInfo',
    'sizeRestrict',
    'sizeRestrictNum',
    'isAutoFontSize',
    'fontFamily',
    'statisticsDimension',
  ],
  Onecode: [
    'width',
    'height',
    'hasControls',
    'item_id',
    'left',
    'top',
    'mHeight',
    'mWidth',
    'showCode',
    'Xscale',
    'Yscale',
    'rotateDeg',
    'isOnecodeNCSuffix',
  ],
  Graphics: [
    'hasControls',
    'item_id',
    'left',
    'top',
    'points',
    'angle',
    'fill',
    'mHeight',
    'mWidth',
    'scaleX',
    'scaleY',
  ],
  Typography: [
    'hasControls',
    'item_id',
    'left',
    'top',
    'mHeight',
    'mWidth',
    'scaleX',
    'scaleY',
    'width',
    'height',
    'plankRotate',
    'showOpenDoor',
    'showEdgeOff',
    'showEdgeDisplay',
    'rotateDeg',
  ],
  Table: [
    'hasControls',
    'item_id',
    'left',
    'top',
    'mHeight',
    'mWidth',
    'scaleX',
    'scaleY',
    'width',
    'height',
    'source_data',
    'fontSize',
    'fontPt',
    'fontFamily',
  ],
  wardrobeNum: ['item_id', 'left', 'top', 'field', 'fontWeight', 'fontFamily'],
  specialMark: [
    'hasControls',
    'item_id',
    'left',
    'top',
    'points',
    'angle',
    'fill',
    'mHeight',
    'mWidth',
    'scaleX',
    'scaleY',
    'opacity',
  ],
  holeSlotPicture: [
    'hasControls',
    'item_id',
    'left',
    'top',
    'mHeight',
    'mWidth',
    'scaleX',
    'scaleY',
    'width',
    'height',
    'holeF',
    'holeZ',
    'uniqueID',
    'defaultScale',
  ],
  'holeSlotPicture-size': [
    'text',
    'lineHeight',
    'fontSize',
    'fontWeight',
    'item_id',
    'fontPt',
    'hasControls',
    'left',
    'top',
    'width',
    'height',
    'textAlign',
    'uniqueID',
    'isShow',
    'selectable',
    'moveF',
    'fontF',
    'showF',
    'positionName',
    'fontFamily',
  ],
  Marking: ['item_id', 'left', 'top', 'field', 'fontWeight', 'fontFamily'],
  SurplusSize: [
    'hasControls',
    'item_id',
    'left',
    'top',
    'mHeight',
    'mWidth',
    'scaleX',
    'scaleY',
    'width',
    'height',
  ],
}
function getAttsValue(item) {
  let data = {}
  needAtts[item.typeName].forEach((e) => {
    if (e == 'scaleX' || e == 'scaleY') {
      if (item[e]) {
        data[e] = item[e]
      } else {
        data[e] = 1
      }
    } else {
      data[e] = item[e]
    }
  })
  return data
}
function handleOneCodeData(item) {
  // 同排版图
  if (item.rotateDeg) {
    item.rotate(0)
  }
  let img = item.getObjects()[0]
  // let text = item.getObjects()[1];
  let data = getDefauleValue(item.typeName, item)
  data.fontPt = img.fontPt
  data.lineHeight = img.lineHeight
  data.fontWeight = img.fontWeight
  data.fontSize = img.fontSize
  data.code_type = img.code_type
  data.source_data = img.source_data
  data.fontFamily = img.fontFamily
  return data
}
function handleTypographyData(item) {
  // 板件如果逆时针旋转后位置会发生变化，保存时需要将旋转的板件图旋转回去，获取到正确的位置
  if (item.plankRotate || item.rotateDeg) {
    item.rotate(0)
  }
  let data = {
    imgItem: {
      left: 10,
      top: 10,
      mWidth: 15,
      mHeight: 25,
    },
    textItems: [],
    ...getDefauleValue(item.typeName, item),
  }
  let texts = item.getObjects().slice(1)
  texts = texts.filter((klass) => !klass.uniqueID)
  texts.forEach((e) => {
    let info = {
      positionName: e.positionName,
      top: e.oTop,
      left: e.oLeft,
      fontSize: e.fontSize,
      fontPt: e.fontPt,
      fontWeight: e.fontWeight,
      fontFamily: e.fontFamily,
    }
    data.textItems.push(info)
  })
  return data
}
function handleWardrobeNumData(item) {
  let items = item.getObjects()
  let cir = items[0]
  let text = items[1]
  let data = getDefauleValue(item.typeName, item)
  data.radius = cir.radius
  data.mRadius = cir.mRadius
  data.fontPt = text.fontPt
  data.fontSize = text.fontSize
  data.fontWeight = text.fontWeight
  data.roomSortWay = text.roomSortWay
  return data
}
function getDefauleValue(typeName, item) {
  let data = {}
  needAtts[typeName].forEach((e) => {
    data[e] = item[e]
  })
  return data
}
