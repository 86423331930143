<template>
  <div class="edit_tagTem_wapper">
    <breadcrumb
      :title="$route.query.line || translateLang('main.sideBar.printTag')"
      :childTitle="translateLang('printPage.editPage.title')"
      @goBackPage="goBackPage"
    >
      <template>
        <div class="topBtn">
          <div class="funBtn" @click="backDefault" id="edit_tag_temp_reset_btn">
            <i class="iconfont icon-recover"></i
            ><span
              class="inline-block ellipsis"
              style="max-width: 100%"
              :title="translateLang('printPage.editPage.reset')"
              >{{ $t('printPage.editPage.reset') }}</span
            >
          </div>
          <div class="funBtn" @click="outEdit" id="edit_tag_temp_quit_btn">
            <i class="iconfont icon-quit"></i
            ><span
              class="inline-block ellipsis"
              style="max-width: 100%"
              :title="translateLang('printPage.editPage.exit')"
              >{{ $t('printPage.editPage.exit') }}</span
            >
          </div>
          <div class="save" @click="save" id="edit_tag_temp_save_btn">
            <i class="iconfont icon-save"></i
            ><span
              class="inline-block ellipsis"
              style="max-width: 100%"
              :title="translateLang('common.save')"
              >{{ $t('common.save') }}</span
            >
          </div>
        </div>
      </template>
    </breadcrumb>
    <div class="com_content">
      <div class="edit_tagTem_left">
        <!-- 标签模板切换 -->
        <div class="temp-switch flex bold color-0">
          <div
            class="align-center tab-nav ellipsis"
            @click="handleSwitchTemplate('plank')"
            :class="{ 'tab-background': labelTemplates != 'plank' }"
            id="edit_tag_temp_plank_tab"
            :title="translateLang('printPage.plankTemp')"
          >
            {{ $t('printPage.plankTemp') }}
          </div>
          <div
            class="align-center tab-nav ellipsis"
            @click="handleSwitchTemplate('surplus')"
            :class="{ 'tab-background': labelTemplates != 'surplus' }"
            id="edit_tag_temp_surplus_tab"
            :title="translateLang('printPage.surplusTemp')"
          >
            {{ $t('printPage.surplusTemp') }}
          </div>
        </div>
        <div class="tagTemBox_info" v-show="labelTemplates == 'plank'">
          <p class="classTitle">
            <span class="dian"></span>
            <span class="title">{{ $t('printPage.editPage.tagInfo') }}</span>
          </p>
          <div class="tagTem_name">
            <span>{{ $t('printPage.editPage.tagName') }}：</span>
            <div>
              <a-input
                id="edit_tag_temp_name_input"
                :placeholder="translateLang('printPage.tempNamePlaceHolder')"
                v-model="tagName"
              />
            </div>
          </div>
          <div class="tagTem_size">
            <span>{{ $t('printPage.editPage.tagSize') }}：</span>
            <a-input-number
              id="edit_tag_temp_size_w_inputNumber"
              v-model="tagSizeWmm"
              :min="1"
              :max="600"
              @change="changeTagSize('w', $event)"
            /><a-icon type="close" />
            <a-input-number
              id="edit_tag_temp_size_h_inputNumber"
              v-model="tagSizeHmm"
              :min="1"
              :max="600"
              @change="changeTagSize('h', $event)"
            />
          </div>
        </div>
        <div class="tagTem_set">
          <p class="classTitle">
            <span class="dian"></span>
            <span class="title">{{ $t('printPage.editPage.tagData') }}</span>
            <!-- <span><i @mouseenter="tipsHover = true" @mouseleave="tipsHover = false" class="iconfont icon-hint" :style="{color:tipsHover?'#18A8C7':'#D2D2D2'}"></i></span>
                    <a-popover title="温馨提示" :visible='tipsHover' placement="right">
                        <template slot="content">
                            <div style="width:150px">您可以根据自身需求拖动下方按钮至右边标签纸上来完成标签模板的编辑</div>
                        </template>
                    </a-popover> -->
          </p>
          <div class="hint">{{ $t('printPage.editPage.dragTip') }}</div>
          <!-- 小板数据标签和操作 -->
          <div class="tagTem_setItem_list">
            <!-- 数据标签的来源 tagTemSetData surplusTemSetData-->
            <div
              v-for="(item, index) in labelTemplates == 'plank'
                ? tagTemSetData
                : surplusTemSetData"
              :key="index"
              :class="nowChooseSet == item.type ? 'active' : ''"
              @mousedown="
                isDraw = true
                mouseInCanvas = false
              "
              @mouseenter="chooseTagSet(item, $event)"
            >
              <span v-if="item.url"
                ><i :class="['iconfont', item.url]"></i
              ></span>
              <!-- 如果找不到icon，就通过image去显示 -->
              <img
                :src="item.imgUrl"
                class="img-class"
                :class="nowChooseSet == item.type ? 'color-cover' : ''"
                v-else
              />
              <span
                class="inline-block ml5 ellipsis"
                :title="translateLang(item.name)"
                >{{ translateLang(item.name) }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="edit_tagTem_mid x-auto">
        <!-- 中部的canvas处理部分 -->
        <div class="hintMessage" v-if="showHintInfo">
          <div>
            <i style="color: #1890ff" class="iconfont icon-hint"></i
            ><span>{{ $t('printPage.editPage.dataTip') }}</span>
          </div>
          <span @click="showHintInfo = false"
            ><i style="fontsize: 12px" class="iconfont icon-close"></i
          ></span>
        </div>
        <!-- 根据切换展示 -->
        <div v-show="labelTemplates == 'plank'">
          <canvas id="mycanvas_tag"></canvas>
        </div>
        <div v-show="labelTemplates == 'surplus'">
          <canvas id="mycanvas_surplus"></canvas>
        </div>
        <!-- 创造一个余料部分的canvas 盛放数据 -->
        <div class="operate_wap">
          <div>
            <a-popover :title="translateLang('common.tip')">
              <template slot="content">
                <p style="width: 200px">
                  {{ $t('printPage.editTip') }}
                </p>
              </template>
              <span class="icon_btn"><i class="iconfont icon-hint"></i></span>
            </a-popover>
          </div>
          <!-- 底部的操作提示弹窗 -->
          <div class="operate_menu">
            <a-popover placement="topLeft">
              <template slot="content">
                <div :style="menuStyle">
                  <span>{{ $t('common.copy') }}</span>
                  <div>
                    <span :style="btnStyle">Ctrl</span>
                    +
                    <span :style="btnStyle">C</span>
                  </div>
                </div>
                <div :style="menuStyle">
                  <span>{{ $t('common.paste') }}</span>
                  <div>
                    <span :style="btnStyle">Ctrl</span>
                    +
                    <span :style="btnStyle">V</span>
                  </div>
                </div>
                <div :style="menuStyle">
                  <span>{{ $t('common.undo') }}</span>
                  <div>
                    <span :style="btnStyle">Ctrl</span>
                    +
                    <span :style="btnStyle">Z</span>
                  </div>
                </div>
                <div :style="menuStyle">
                  <span>{{ $t('arrangedPage.zoomScale') }}</span>
                  <div>
                    <span :style="btnStyle">Ctrl</span>
                    +
                    <span style="font-size: 12px">{{
                      $t('arrangedPage.mouseRoll')
                    }}</span>
                  </div>
                </div>
                <div :style="menuStyle">
                  <span>{{ $t('common.move') }}</span>
                  <div>
                    <span>{{ $t('arrangedPage.mouseRight') }}</span>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    padding: 9px 0;
                    width: 180px;
                  "
                >
                  <span>{{ $t('common.delete') }}</span>
                  <div>
                    <span :style="btnStyle">Delete</span>
                  </div>
                </div>
              </template>
              <template slot="title">
                <span>{{ $t('arrangedPage.shortCut') }}</span>
              </template>
              <span class="icon_btn"
                ><i class="iconfont icon-keyboard"></i
              ></span>
            </a-popover>
          </div>
          <!-- 底部缩放按钮 -->
          <div class="zoom_canvas">
            <span class="icon_btn" @click="changeZoom(-0.01)"
              ><i class="iconfont icon-narrow"></i
            ></span>
            <span>{{ calcZoom }}</span>
            <span class="icon_btn" @click="changeZoom(0.01)"
              ><i class="iconfont icon-enlarge"></i
            ></span>
          </div>
        </div>
      </div>
      <!-- 右边具体的数据信息 -->
      <div class="edit_tagTem_right y-auto" v-if="nowChooseSet && currentItem">
        <div class="attBox">
          <p class="classTitle">
            <span class="dian"></span>
            <span class="title">{{ $t('printPage.editPage.attribute') }}</span>
          </p>
          <div>
            <div v-show="attItemShow(attShowInSet.imgWH)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="`${
                  nowChooseSet == 'Onecode' || nowChooseSet == 'QRcode'
                    ? translateLang('printPage.oneCode')
                    : translateLang('printPage.image')
                }${translateLang('workShopPage.size')}`"
                >{{
                  nowChooseSet == 'Onecode' || nowChooseSet == 'QRcode'
                    ? translateLang('printPage.oneCode')
                    : translateLang('printPage.image')
                }}{{ $t('workShopPage.size') }}：</span
              >
              <a-input-number
                id="inputNumber"
                v-model="codeW"
                :min="1"
                :max="600"
                @change="changeImgSize('w', $event)"
              /><a-icon type="close" />
              <a-input-number
                id="inputNumber"
                v-model="codeH"
                :min="1"
                :max="600"
                @change="changeImgSize('h', $event)"
              />
            </div>
            <div v-show="attItemShow(attShowInSet.picture)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.holeSlotPicZoom')"
                >{{ $t('printPage.editPage.holeSlotPicZoom') }}：</span
              >
              <el-button
                icon="el-icon-minus"
                circle
                size="mini"
                @click="changePictureScale(null, -0.01)"
                :disabled="pictureScale <= 0.1"
              ></el-button>
              <el-slider
                v-model="pictureScale"
                :min="0.1"
                :max="2"
                :step="0.01"
                @change="changePictureScale"
              ></el-slider>
              <el-button
                icon="el-icon-plus"
                circle
                size="mini"
                @click="changePictureScale(null, 0.01)"
                :disabled="pictureScale >= 2"
              ></el-button>
            </div>
            <div v-show="attItemShow(attShowInSet.angle)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.rotateDeg')"
                >{{ $t('printPage.editPage.rotateDeg') }}：</span
              >
              <a-input-number
                ref="angleRef"
                id="inputNumber"
                v-model="angle"
                @change="changeAngle"
              />
            </div>
            <div v-show="attItemShow(attShowInSet.angle)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.colorDeep')"
                >{{ $t('printPage.editPage.colorDeep') }}：</span
              >
              <a-select
                v-model="GraColor"
                style="width: 100px"
                @change="handleGraColorChange"
              >
                <a-select-option
                  v-for="province in GraColorData"
                  :key="province.value"
                  :title="province.label"
                >
                  {{ province.label }}
                </a-select-option>
              </a-select>
            </div>
            <div
              v-show="
                attItemShow(attShowInSet.dataS) && nowChooseSet == 'DataSource'
              "
            >
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.dataSize')"
                >{{ $t('printPage.editPage.dataSize') }}：</span
              >
              <div
                class="flex flex-cross--center"
                style="justify-content: space-between"
              >
                <a-input
                  style="width: 75px"
                  v-model="dataSourceWidth"
                  @keydown="handleChangeDataSourceSize"
                  @focus="handleFocusRectSize"
                  @blur="handleBlurRectSize"
                ></a-input>
                <span style="margin: 0 10px">X</span>
                <a-input
                  style="width: 75px"
                  v-model="dataSourceHeight"
                  @keydown="handleChangeDataSourceSize"
                  @focus="handleFocusRectSize"
                  @blur="handleBlurRectSize"
                ></a-input>
              </div>
            </div>
            <div v-show="attItemShow(attShowInSet.opacity)">
              <span class="att-name"
                >{{ $t('printPage.editPage.dataSource') }}：</span
              >
              <a-select
                v-model="partSignValue"
                style="width: 180px"
                @change="handlePartSign"
              >
                <a-select-option
                  v-for="province in partSign"
                  :key="province.value"
                  :title="$t(province.label)"
                >
                  {{ $t(province.label) }}
                </a-select-option>
              </a-select>
            </div>
            <div v-show="attItemShow(attShowInSet.opacity)">
              {{ $t('printPage.opacity') }}
              <a-slider
                v-model="inputOpacity"
                :min="0"
                :max="1"
                :step="0.01"
                style="width: 50%"
                @change="
                  (value) => {
                    changeOpacityOrScaleSize(value, 'opacity')
                  }
                "
              />
              {{ inputOpacity }}
            </div>
            <div
              v-show="
                attItemShow(attShowInSet.dataS) && nowChooseSet == 'DataSource'
              "
            >
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.dataSource')"
                >{{ $t('printPage.editPage.dataSource') }}：</span
              >
              <a-select
                v-model="nowDataInfo"
                style="width: 180px"
                show-search
                :filter-option="filterOption"
                @change="handleDataSourcesChange"
              >
                <a-select-opt-group
                  v-for="(item, index) in groupDataSource"
                  :label="item.label"
                  :key="index"
                >
                  <a-select-option
                    v-for="(option, idx) in item.options"
                    :key="`${index}-${idx}`"
                    :value="option.value"
                    :title="option.label"
                  >
                    {{ option.label }}
                  </a-select-option>
                </a-select-opt-group>
              </a-select>
            </div>
            <div
              v-show="
                attItemShow(attShowInSet.dataS) && nowChooseSet != 'DataSource'
              "
            >
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.dataSource')"
                >{{ $t('printPage.editPage.dataSource') }}：</span
              >
              <a-select
                v-if="nowChooseSet === 'Table'"
                v-model="nowTableData"
                style="width: 180px"
                @change="handleTableChange"
              >
                <a-select-option
                  v-for="table in tableSource"
                  :key="table.value"
                  :title="table.label"
                >
                  {{ translateLang(table.label) }}
                </a-select-option>
              </a-select>
              <a-select
                v-else
                v-model="nowCodeInfo"
                style="width: 180px"
                @change="handleCodeChange"
              >
                <a-select-option
                  v-for="province in nowDataSources"
                  :key="province.value"
                  :title="province.label"
                >
                  {{ province.label }}
                </a-select-option>
              </a-select>
            </div>
            <div
              v-show="
                attItemShow(attShowInSet.dataS) && nowChooseSet == 'QRcode'
              "
            >
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.QRCodeFormat')"
                >{{ $t('printPage.editPage.QRCodeFormat') }}：</span
              >
              <a-radio-group
                v-model="QRCodeFormat"
                id="printtag_qrcode_format_radio"
                @change="handleChangeQRCodeFormat"
              >
                <a-radio value="QRCode">{{
                  $t('printPage.editPage.formatQR')
                }}</a-radio>
                <br />
                <a-radio value="DMCode">{{
                  $t('printPage.editPage.formatDM')
                }}</a-radio>
              </a-radio-group>
            </div>
            <div
              class="ncname-remark p0 fs12"
              v-show="nowCodeInfo === 'ncName' && nowChooseSet === 'Onecode'"
            >
              {{ $t('printPage.editPage.NCFileNameTip') }}
            </div>
            <!-- 板件特殊标注选项 -->
            <div v-show="attItemShow(attShowInSet.partSign)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.dataSource')"
                >{{ $t('printPage.editPage.dataSource') }}：</span
              >
              <a-select
                v-model="partSignValue"
                style="width: 180px"
                @change="handlePartSign"
              >
                <a-select-option
                  v-for="province in partSign"
                  :key="province.value"
                  :title="$t(province.label)"
                >
                  {{ translateLang(province.label) }}
                </a-select-option>
              </a-select>
            </div>
            <!-- 柜体序号-按房间排序的后续表单项 -->
            <div
              v-show="
                attItemShow(attShowInSet.partSign) &&
                partSignValue === 'wardrobeNumToRoom'
              "
            >
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.roomNo')"
                >{{ $t('printPage.editPage.roomNo') }}：</span
              >
              <a-select
                v-model="roomSortWay"
                style="width: 180px"
                @change="handleChangeRoomSort"
              >
                <a-select-option
                  v-for="sort in roomSort"
                  :key="sort.value"
                  :title="sort.label"
                >
                  {{ translateLang(sort.label) }}
                </a-select-option>
              </a-select>
            </div>
            <div v-show="nowCodeInfo === 'ncName'">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.genNCSuffix')"
                >{{ $t('printPage.editPage.genNCSuffix') }}：</span
              >
              <a-checkbox
                v-if="nowChooseSet === 'QRcode'"
                v-model="isQRcodeNCSuffix"
                @change="handleChangeQRNCSuffix"
              ></a-checkbox>
              <a-checkbox
                v-else
                v-model="isOnecodeNCSuffix"
                @change="handleChangeOneNCSuffix"
              ></a-checkbox>
            </div>
            <div
              v-show="
                attItemShow(attShowInSet.dataS) &&
                nowChooseSet != 'DataSource' &&
                nowChooseSet !== 'Table' &&
                currentItem &&
                (nowChooseSet == 'QRcode'
                  ? currentItem.source_data == 'oriPlankNum'
                  : currentItem?.getObjects?.()[0].source_data ==
                    'oriPlankNum') &&
                labelTemplates !== 'surplus'
              "
            >
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.noHoleSlot')"
                >{{ $t('printPage.editPage.noHoleSlot') }}：</span
              >
              <a-switch
                v-model="switchCodeValue"
                @change="changeCodeShowValue"
              />
            </div>
            <div v-show="attItemShow(attShowInSet.codeAtt)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.rotate')"
                >{{ $t('printPage.editPage.rotate') }}：</span
              >
              <a-select
                v-model="codeRotateDeg"
                style="width: 180px"
                @change="handleCodeRotateDegChange"
              >
                <a-select-option
                  v-for="rotate in rotateOptions"
                  :key="rotate.value"
                  :value="rotate.value"
                  :title="rotate.label"
                >
                  {{ rotate.label }}
                </a-select-option>
              </a-select>
            </div>
            <div v-show="attItemShow(attShowInSet.codeAtt)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.barcodeAtrribute')"
                >{{ $t('printPage.editPage.barcodeAtrribute') }}：</span
              >
              <a-select
                v-model="codeAtt"
                style="width: 180px"
                @change="handleCodeAttChange"
              >
                <a-select-option
                  v-for="province in codeAttOpeion"
                  :key="province"
                  :title="province"
                >
                  {{ province }}
                </a-select-option>
              </a-select>
            </div>
            <div v-show="attItemShow(attShowInSet.diameter)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.circleD')"
                >{{ $t('printPage.editPage.circleD') }}：</span
              >
              <a-select
                v-model="nowDiameter"
                style="width: 180px"
                @change="handleDiameterChange"
              >
                <a-select-option
                  v-for="province in diameterOpeion"
                  :key="province"
                  :title="province"
                >
                  {{ province }}
                </a-select-option>
              </a-select>
            </div>
            <div
              v-show="
                attItemShow(
                  labelTemplates == 'plank'
                    ? attShowInSet.fontFamily
                    : attShowInSet.fontFamily.replace('Typography', '')
                )
              "
            >
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.fontFamily')"
                >{{ $t('printPage.editPage.fontFamily') }}：</span
              >
              <a-select
                v-model="nowFontFamily"
                style="width: 180px"
                @change="handleFontFamilyChange($event, currentItem)"
              >
                <a-select-option
                  v-for="province in fontFamilyArr"
                  :key="province"
                  :title="province"
                >
                  {{ province }}
                </a-select-option>
              </a-select>
            </div>
            <div
              v-show="
                attItemShow(
                  labelTemplates == 'plank'
                    ? attShowInSet.fontS
                    : attShowInSet.fontS.replace('Typography', '')
                )
              "
              class="relative mb5"
            >
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.fontSize')"
                >{{ $t('printPage.editPage.fontSize') }}：</span
              >
              <el-select
                v-model="nowFontSize"
                style="width: 180px"
                @change="handleFontSizeChange($event, currentItem)"
                filterable
                allow-create
                default-first-option
                size="small"
              >
                <el-option
                  v-for="province in fontSizeOpeion"
                  :key="province"
                  :value="province"
                  :label="province"
                ></el-option>
              </el-select>
              <transition name="errorInfo">
                <span
                  v-show="inputAlert.fontSizeAlert"
                  class="error-info absolute fs12"
                >
                  {{ inputAlert.fontSizeAlert }}
                </span>
              </transition>
            </div>
            <div
              v-show="
                attItemShow(
                  labelTemplates == 'plank'
                    ? attShowInSet.fontW
                    : attShowInSet.fontW.replace('Typography', '')
                )
              "
            >
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.fontWeight')"
                >{{ $t('printPage.editPage.fontWeight') }}：</span
              >
              <a-checkbox
                v-model="fontWeight"
                @change="changeFontWeight(currentItem)"
              ></a-checkbox>
              <span class="fs12" style="color: #ccc"
                >（{{ $translateLang('标签PDF不支持') }}）</span
              >
            </div>
            <div v-show="attItemShow(attShowInSet.rotate)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.imgRotate')"
                >{{ $t('printPage.editPage.imgRotate') }}：</span
              >
              <a-select
                v-model="rotateDeg"
                style="width: 180px"
                @change="changePlankRotate"
              >
                <a-select-option
                  v-for="rotateOption in rotateOptions"
                  :key="rotateOption.value"
                  :value="rotateOption.value"
                  :title="rotateOption.label"
                >
                  {{ rotateOption.label }}
                </a-select-option>
              </a-select>
            </div>
            <template v-if="labelTemplates == 'plank'">
              <div v-show="attItemShow(attShowInSet.openDoorD)">
                <span
                  class="att-name inline-block ellipsis"
                  style="max-width: 110px"
                  >{{ $t('printPage.editPage.showOpenSide') }}：</span
                >
                <a-checkbox
                  v-model="showOpenDoor"
                  @change="changeShowOpenDoor"
                ></a-checkbox>
              </div>
              <div v-show="attItemShow(attShowInSet.edgeOff)">
                <span
                  class="att-name inline-block ellipsis"
                  style="max-width: 110px"
                  :title="translateLang('printPage.editPage.noEdgeDisplay')"
                  >{{ $t('printPage.editPage.noEdgeDisplay') }}：</span
                >
                <a-checkbox
                  v-model="showEdgeOff"
                  @change="changeShowEdgeOff"
                ></a-checkbox>
              </div>
              <div v-show="attItemShow(attShowInSet.edgeShow)">
                <span
                  class="att-name inline-block ellipsis"
                  style="max-width: 110px"
                  :title="translateLang('printPage.editPage.allEdgeNoDisplay')"
                  >{{ $t('printPage.editPage.allEdgeNoDisplay') }}：</span
                >
                <a-checkbox
                  v-model="showEdgeDisplay"
                  @change="changeShowEdgeDisplay"
                ></a-checkbox>
              </div>
            </template>
            <div v-show="attItemShow(attShowInSet.wrap)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.autoRow')"
                >{{ $t('printPage.editPage.autoRow') }}：</span
              >
              <a-checkbox
                v-model="splitByGrapheme"
                @change="changeWordWrap"
              ></a-checkbox>
            </div>
            <div v-show="attItemShow(attShowInSet.lineS)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.rowMargin')"
                >{{ $t('printPage.editPage.rowMargin') }}：</span
              >
              <a-select
                v-model="lineSpace"
                style="width: 180px"
                @change="handleLineSpaceChange"
              >
                <a-select-option
                  v-for="province in lineSpaceOpeion"
                  :key="province"
                  :title="province"
                >
                  {{ province }}
                </a-select-option>
              </a-select>
            </div>
            <div v-show="attItemShow(attShowInSet.lineS)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.autoFontSize')"
                >{{ $t('printPage.editPage.autoFontSize') }}：</span
              >
              <a-checkbox
                v-model="isAutoFontSize"
                @change="handleChangeAutoFontSize"
              ></a-checkbox>
            </div>
            <!-- 统计维度 -->
            <div
              v-if="
                attItemShow(attShowInSet.statistics) &&
                currentItem.source_data == 'sameSizeBoardCount'
              "
              class="statistics-box"
            >
              <p>{{ $t('printPage.editPage.statisticsDimension') }}：</p>
              <!-- 单选框组件 -->
              <a-radio-group
                default-value="noLimit"
                v-model="statisticsDimension"
                @change="handleChangeStatisticsDimension"
                id="edit_tag_temp_statistics_radio"
              >
                <a-radio value="noLimit" id="edit_tag_temp_statistics_noLimit">
                  {{ $t('printPage.editPage.noLimit') }}
                </a-radio>
                <a-radio
                  value="sameOrder"
                  id="edit_tag_temp_statistics_sameOrder"
                >
                  {{ $t('printPage.editPage.sameOrderNo') }}
                </a-radio>
                <a-radio
                  value="sameRoom"
                  id="edit_tag_temp_statistics_sameRoom"
                >
                  {{ $t('printPage.editPage.sameRoom') }}
                </a-radio>
                <a-radio
                  value="sameWardrobe"
                  id="edit_tag_temp_statistics_sameWardrobe"
                >
                  {{ $t('printPage.editPage.sameWardrobe') }}
                </a-radio>
              </a-radio-group>
            </div>
            <div v-show="attItemShow(attShowInSet.textAlign)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.alignWay')"
                >{{ $t('printPage.editPage.alignWay') }}：</span
              >
              <a-select
                v-model="textAlign"
                style="width: 100px"
                @change="handleTextAlignChange"
              >
                <a-select-option
                  v-for="province in textAlignOption"
                  :key="province.value"
                  :title="translateLang(province.label)"
                >
                  {{ $t(province.label) }}
                </a-select-option>
              </a-select>
            </div>
            <!-- 限制小数点位数 余料模板不显示 -->
            <template v-if="mycanvasId != 'mycanvas_surplus'">
              <div
                v-show="
                  attItemShow(attShowInSet.dataS) &&
                  (currentItem.source_data == 'oSize' ||
                    currentItem.source_data == 'cutSize')
                "
              >
                <span
                  class="att-name inline-block ellipsis"
                  style="max-width: 110px"
                  :title="translateLang('printPage.editPage.limitFixed')"
                  >{{ $t('printPage.editPage.limitFixed') }}：</span
                >
                <a-checkbox
                  v-model="sizeRestrict"
                  @change="changeSizeRestrict"
                ></a-checkbox>
              </div>
            </template>

            <!-- 限制小数点位数输入框 余料模板不显示 -->
            <template v-if="mycanvasId != 'mycanvas_surplus'">
              <div
                v-show="
                  attItemShow(attShowInSet.dataS) &&
                  (currentItem.source_data == 'oSize' ||
                    currentItem.source_data == 'cutSize') &&
                  sizeRestrict
                "
              >
                <span
                  class="att-name inline-block ellipsis"
                  style="max-width: 110px"
                  :title="translateLang('printPage.editPage.digitLimitation')"
                  >{{ $t('printPage.editPage.digitLimitation') }}</span
                >
                <a-input-number
                  ref="sizeRestrictNumRef"
                  v-model="sizeRestrictNum"
                  @change="changeSizeRestrictNum"
                  style="width: 75px"
                  :min="0"
                  :max="5"
                />
              </div>
            </template>

            <div v-show="attItemShow(attShowInSet.holeSize)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.showFrontHS')"
                >{{ $t('printPage.editPage.showFrontHS') }}：</span
              >
              <a-checkbox
                v-model="holeZ"
                @change="changeIsHole('holeZ')"
              ></a-checkbox>
            </div>
            <div v-show="attItemShow(attShowInSet.holeSize)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.showBackHS')"
                >{{ $t('printPage.editPage.showBackHS') }}：</span
              >
              <a-checkbox
                v-model="holeF"
                @change="changeIsHole('holeF')"
              ></a-checkbox>
            </div>
            <div v-show="attItemShow(attShowInSet.holeSize)">
              <span
                class="att-name inline-block ellipsis"
                style="max-width: 110px"
                :title="translateLang('printPage.editPage.showPlankSize')"
                >{{ $t('printPage.editPage.showPlankSize') }}：</span
              >
              <a-checkbox
                v-model="plankSize"
                @change="changePlankSize"
              ></a-checkbox>
            </div>
          </div>
        </div>
        <div class="delete_reset">
          <p class="classTitle">
            <span class="dian"></span>
            <span class="title">{{
              $t('printPage.editPage.otherOpration')
            }}</span>
          </p>
          <div>
            <a-button @click="deleteEl()">{{ $t('common.delete') }}</a-button>
            <a-button @click="resetParameter" v-if="isShowReset">
              {{ $t('printPage.editPage.attributeReset') }}
            </a-button>
            <a-button
              v-for="(item, idx) in otherOperate"
              :key="idx"
              :disabled="item.isDisable(activeElementCount)"
              @click="handleOtherClick(item)"
            >
              {{ $t(item.text) }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLabelInfo } from '@/apis/tag'
import surplus_size from '@/assets/surplus_size.png'
import surplus_tag from '@/assets/surplus_tag.png'
import breadcrumb from '@/components/topBreadCrumb.vue'
import store from '@/store'
import { translate } from '@/util/commonFun'
import { PxToPt, getPathMinMaxPoint } from '@/util/plankCommonFuncs'
import { calcTextSize, dealTextStr, fabricTable } from '@/util/tag/tag-draw'
import { needApartPackUser } from '@/util/thirdPartyDealFunc/lblDealFuncs'
import surplusFieldData from '@/views/printTag/utils/surplusFieldData'
import { fabric } from 'fabric'
import JsBarcode from 'jsbarcode'
import { nanoid } from 'nanoid'
import { mapState } from 'vuex'

import defaultTagData from '../../util/defaultTagData'
import mockData from '../../util/editTagMock'
import { PtToPx, getDeviceXDPI, mmToPx, pxTomm } from '../../util/exportFuncs'
import { handleTagSaveData } from '../../util/handleTagSaveData'
import { otherOperate } from './data'
import {
  FabricMovingControl,
  FabricSelectedControl,
  getActiveObjectByNotAssignField,
  getElRealRect,
  getPositionByCanvas,
  isControlRectEl,
  isSelectedEl,
  setPositionByTopLeft,
} from './utils/fabricControl'

/** 自定义 fabric 控制类数组 */
const fabricControlList = []
const fabricMovingControlList = []

/** 当前拥有的canvas 画布 id */
const canvasIdList = ['mycanvas_tag', 'mycanvas_surplus']
/*
定义每种标签数据的type
FixedText  DataSource Graphics(图形) Typography(排版图) Onecode QRcode
 */
const attShowInSet = {
  fontS:
    'FixedText,DataSource,Typography,Onecode,Table,wardrobeNum,multipleSelect,holeSlotPicture,Marking,SurplusSize',
  fontFamily:
    'FixedText,DataSource,Typography,Onecode,Table,wardrobeNum,multipleSelect,holeSlotPicture,Marking,SurplusSize',
  fontW:
    'FixedText,DataSource,Typography,Onecode,wardrobeNum,multipleSelect,holeSlotPicture,Marking,SurplusSize',
  lineS: 'FixedText,DataSource,Onecode',
  dataS: 'DataSource,QRcode,Onecode,Table',
  imgWH: 'Graphics,Typography,Onecode,QRcode,SurplusSize',
  // 透明度（拖动，最大值为1）
  opacity: 'specialMark',
  codeAtt: 'Onecode',
  angle: 'Graphics',
  color: 'Graphics',
  diameter: 'wardrobeNum,Marking',
  wrap: 'FixedText',
  textAlign: 'FixedText',
  statistics: 'DataSource',
  multipleSelect: 'multipleSelect',
  holeSize: 'holeSlotPicture',
  picture: 'holeSlotPicture',
  rotate: 'Typography',
  openDoorD: 'Typography',
  edgeOff: 'Typography',
  edgeShow: 'Typography',
  partSign: 'wardrobeNum',
}

const DPI = getDeviceXDPI()
export default {
  components: {
    breadcrumb,
  },
  data() {
    return {
      otherOperate: otherOperate,
      mycanvasId: 'mycanvas_tag',
      // 当前选中的模板标签
      labelTemplates: 'plank',
      // 显示提示信息
      showHintInfo: true,
      inputOpacity: 0.25,
      inputScale: 1,
      switchCodeValue: true,
      tipsHover: false,
      tagId: 0,
      attShowInSet: attShowInSet,
      tagSizeWmm: 60,
      tagSizeHmm: 40,
      QRCodeFormat: 'QRCode',
      tagName: '',
      nowChooseSet: '',
      chooseOtherSet: true,
      nowFontSize: 8,
      lineSpace: 1,
      fontWeight: false,
      // 是否限制成品/切割尺寸
      sizeRestrict: false,
      // 成品/切割尺寸限制小数点位数
      sizeRestrictNum: 2,
      codeW: 10,
      codeH: 10,
      nowDataInfo: '',
      nowCodeInfo: 'plankNum',
      nowTableData: '',
      codeAtt: 'EAN13',
      angle: 0,
      GraColor: '#000000',
      nowDiameter: 4,
      arrDPI: [],
      GraColorData: mockData.bgColor,
      // 余料板件和裁剪板件的数据标签
      surplusTemSetData: [
        {
          name: 'printPage.editPage.text',
          url: 'icon-text',
          type: 'FixedText',
        },
        {
          name: 'printPage.editPage.dataSource',
          url: 'icon-data',
          type: 'DataSource',
        },
        {
          name: 'printPage.editPage.surplusMark',
          url: '',
          type: 'Marking',
          imgUrl: surplus_tag,
        },
        {
          name: 'printPage.editPage.typograpyPic',
          url: 'icon-typesetting',
          type: 'Typography',
        },
        {
          name: 'printPage.editPage.barCode',
          url: 'icon-bar_code',
          type: 'Onecode',
        },
        {
          name: 'printPage.editPage.QRCode',
          url: 'icon-qr_code',
          type: 'QRcode',
        },
        {
          name: 'printPage.editPage.surplusSizePic',
          url: '',
          type: 'SurplusSize',
          imgUrl: surplus_size,
        },
      ],
      tagTemSetData: [
        {
          name: 'printPage.editPage.text',
          url: 'icon-text',
          type: 'FixedText',
        },
        {
          name: 'printPage.editPage.dataSource',
          url: 'icon-data',
          type: 'DataSource',
        },
        {
          name: 'printPage.editPage.graph',
          url: 'icon-graph',
          type: 'Graphics',
        },
        {
          name: 'printPage.editPage.typograpyPic',
          url: 'icon-typesetting',
          type: 'Typography',
        },
        {
          name: 'printPage.editPage.barCode',
          url: 'icon-bar_code',
          type: 'Onecode',
        },
        {
          name: 'printPage.editPage.QRCode',
          url: 'icon-qr_code',
          type: 'QRcode',
        },
        {
          name: 'printPage.editPage.plankMark',
          url: 'icon-xuhao',
          type: 'wardrobeNum',
        },
        {
          name: 'printPage.editPage.holeSlotPic',
          url: 'icon-kongcao',
          type: 'holeSlotPicture',
        },
        {
          name: 'printPage.editPage.table',
          url: 'icon-biaogebeifen',
          type: 'Table',
        },
      ],
      textAlignOption: [
        {
          value: 'left',
          label: 'printPage.editPage.JustifyLeft',
        },
        {
          value: 'right',
          label: 'printPage.editPage.JustifyRight',
        },
        {
          value: 'center',
          label: 'printPage.editPage.JustifyCenter',
        },
      ],
      tableSource: [
        {
          value: 'extraParts',
          label: 'printPage.editPage.extraList',
        },
      ],
      diameterOpeion: [4, 6, 8, 10],
      fontSizeOpeion: [4, 6, 8, 10, 12, 14, 16, 20, 26, 32],
      lineSpaceOpeion: [1, 2, 3, 4],
      dataSources: {
        codeData: [
          { label: '安装图', value: 'ggid' },
          { label: '板件条码', value: 'oriPlankNum' },
          { label: '正面条码', value: 'plankNum' },
          { label: '反面条码', value: 'plankNumF' },
          { label: '订单备注', value: 'remarks' },
          { label: '下料文件名', value: 'ncName' },
          { label: '房间备注', value: 'remark' },
          { label: '尺寸信息', value: 'size' },
        ],
        onCodeData: [
          { label: '板件条码', value: 'oriPlankNum' },
          { label: '板件条码(无标识)', value: 'oriPlankNumNoMark' },
          { label: '正面条码', value: 'plankNum' },
          { label: '反面条码', value: 'plankNumF' },
          { label: '订单备注', value: 'remarks' },
          { label: '下料文件名', value: 'ncName' },
          { label: '房间备注', value: 'remark' },
        ],
        surplusCodeList: [
          { label: '板件条码', value: 'oriPlankNum' },
          { label: '余料入库码', value: 'restockingCode' },
        ],
        suplusOnCodeList: [{ label: '板件条码', value: 'oriPlankNum' }],
        fieldData: null,
        // 余料部分的数据信息标签，先写死
        surplusFieldData: surplusFieldData,
      },
      codeAttOpeion: mockData.codeAttOpeion,
      currentItem: null, // 当前选中的组件
      // currentDelList: [], // 多选删除标签
      // item_id: 0, //确保每个组件的唯一性，新增组件则加1
      isAllSelect: false,
      group: [],
      overUpPoint: { x: 0, y: 0 },
      mouseInCanvas: false,
      nowETarget: null, //当前鼠标下的元素
      isDraw: false, //是否可以绘制，只有点击标签数据栏拖动才能绘制
      panning: false,
      mycanvas_tag: null,
      mycanvas_surplus: null,
      setTagTimer: null,
      // 标签数据
      tagSizeWPx: 0,
      tagSizeHPx: 0,
      // 保存标签纸位置
      rectData: null, //记录当前纸张信息
      saveCanvas: null, //保存初始画布数据
      loadTime: null, // 定时器
      copyValue: [], // 复制值
      operateArrs: [], //保存操作顺序的数据
      mods: 0, //记录当前撤销的位置
      mouse_wheel: false, //记录是否可滑动滚轮缩放大小
      zoomNum: 2, //当前放大倍数
      canvasZoomPoint: {}, //记录缩放的中点
      splitByGrapheme: false, //自动换行
      textAlign: 'left', // 对齐方式
      // 记录是否是第三方跳转
      thinkerxMaterial: false,
      plankSignBox: [],
      holeZ: true, // 正面孔槽
      holeF: true, // 反面孔槽
      plankSize: true, // 板件尺寸,
      pictureScale: 1, //图像缩放大小
      plankRotate: false, // 板件逆时针旋转90°
      showOpenDoor: true, // 开门方向
      showEdgeOff: false,
      showEdgeDisplay: false,
      partSign: mockData.partSign,
      partSignValue: 'wardrobeNum',
      // 记录是否移动画布
      isFabricCanvasMove: false,
      // 记录右键点击的位置
      fabricCutPoint: null,
      //记录已生成过边框的元素
      hasGenControlRectList: [],
      //记录生成的边框元素
      controlRects: [],
      // 数据信息宽度
      dataSourceWidth: 100,
      // 数据信息高度
      dataSourceHeight: 20,
      // 旋转角度
      rotateOptions: [
        {
          label: '0°',
          value: 0,
        },
        {
          label: '90°',
          value: 90,
        },
        {
          label: '180°',
          value: 180,
        },
        {
          label: '270°',
          value: 270,
        },
      ],
      rotateDeg: 0,
      // 超出文本字体自动调整
      isAutoFontSize: true,
      // 条码旋转角度
      codeRotateDeg: 90,
      // 二维码生成NC后缀
      isQRcodeNCSuffix: true,
      // 条形码生成NC后缀
      isOnecodeNCSuffix: true,
      // 记录各种输入警示
      inputAlert: {
        fontSizeAlert: '',
      },
      nowFontFamily: '微软雅黑',
      fontFamilyArr: ['微软雅黑', '黑体', '宋体'],
      // 是否展示重置参数
      isShowReset: true,
      // 房间，柜体排序方式
      roomSort: mockData.roomSort,
      // 房间排序方式
      roomSortWay: '',
      // 柜体排序方式
      wardrobeSortWay: '',
      // 统计维度
      statisticsDimension: 'noLimit',
      // 记录键盘状态
      keyboard: {
        /** 是否按下了ctrl键 */
        isCtrlDown: false,
      },
      /** 记录当前激活的元素个数 */
      activeElementCount: 1,
    }
  },
  methods: {
    // 模板切换的方法
    handleSwitchTemplate(type) {
      if (type == 'plank') {
        this.labelTemplates = 'plank'
        this.mycanvasId = 'mycanvas_tag'
      } else {
        this.labelTemplates = 'surplus'
        this.mycanvasId = 'mycanvas_surplus'
      }
      this.clearControlRect()
      this.currentCanvas.discardActiveObject()
      const [_, first] = this.currentCanvas.getObjects()
      if (first) {
        // this.currentCanvas.setActiveObject(first)
        if (first.typeName == 'DataSource') {
          this.genControlRect(first)
        }
        this.getKclassValueToAttr(first)
      }
      this.currentItem = first
    },
    changeZoom(value) {
      if (this.zoomNum <= 2.99 || this.zoomNum > 0.01) {
        this.zoomNum += value
        this[this.mycanvasId].zoomToPoint(this.canvasZoomPoint, this.zoomNum)
      }
    },
    outEdit() {
      this.$router.go(-1)
    },
    goBackPage() {
      this.$router.go(-1)
    },
    save() {
      if (!this.tagName) {
        this.$message({
          message: translate('printPage.editPage.tempNamePlaceholder'),
          type: 'warning',
          duration: 1500,
        })
        return
      }
      this.currentCanvas.discardActiveObject()
      // 获取到小板模板和余料模板
      let saveData = this['mycanvas_tag']
        .getObjects()
        .filter(
          (klass) => !klass.isDeleteRect || klass.typeName !== 'controlRect'
        )
      // 余料的数据信息
      let surplusSaveData = this['mycanvas_surplus']
        .getObjects()
        .filter(
          (klass) => !klass.isDeleteRect || klass.typeName !== 'controlRect'
        )
      let mock = handleTagSaveData(saveData).filter(
        (item) => item.type !== 'controlRect'
      )
      let surplusMock = handleTagSaveData(surplusSaveData).filter(
        (item) => item.type !== 'controlRect'
      )
      let img = saveData[0]
      let rectInfo = {
        left: img.left,
        top: img.top,
      }
      let name = this.tagName
      let data = {
        tem_data: mock,
        tag_width: this.tagSizeWmm,
        tag_height: this.tagSizeHmm,
        rectInfo: rectInfo,
        surplus_data: surplusMock,
      }
      let payload = { name, data }
      if (this.tagId) {
        payload.id = Number(this.tagId)
      }
      this.$token('/save_tag_setting', payload, (res) => {
        if (res.status == 1) {
          this.$message({
            message: translate('common.saveSuccess'),
            type: 'success',
            duration: 1000,
          })
          // setTimeout(() => {
          //     this.$router.go(-1)
          // },300)
          this.saveCanvas = payload.data
          this.saveCanvas.name = payload.name
        } else {
          this.$message({
            message: `${res.msg},${res.data.err_msg}`,
            type: 'error',
            duration: 1000,
          })
        }
      })
    },
    resetParameter() {
      const allObjects = this.currentCanvas.getObjects()
      const activeObjects = this.currentCanvas
        .getActiveObjects()
        .map((object) => {
          // 当活跃对象是控制元素时寻找其控制的元素
          if (isControlRectEl(object)) {
            return allObjects.find(
              (_object) =>
                _object !== object && _object.item_id === object.item_id
            )
          }
          return object
        })
      activeObjects.forEach((current) => {
        if (current?.opacity && current?.opacity !== 0.25) {
          current.opacity = 0.25
        }
        // 需要先将板件图转成正常位置
        if (current.typeName == 'Typography' && current.plankRotate) {
          current.rotate(0)
        }
        const { left, top } = getPositionByCanvas(current)
        this.currentCanvas.remove(current)
        // 删除孔槽图的子集
        if (current.typeName == 'holeSlotPicture') {
          current.auxiliaryEl?.forEach((klass) => {
            this.currentCanvas.remove(klass)
          })
        }
        this.resetData(current.typeName)
        this.reDrawByCurrentPostion({ x: left, y: top }, current.typeName)
        // 取消选区状态，避免一些意外的显示效果
        this.currentCanvas.discardActiveObject()
      })
    },
    backDefault() {
      // if(this.tagId){
      let deleteObjs = this[this.mycanvasId].getObjects()
      for (let i in deleteObjs) {
        this[this.mycanvasId].remove(deleteObjs[i])
      }
      let backData = this.saveCanvas
      this.currentItem = null
      this.tagSizeWmm = backData.tag_width
      this.tagSizeHmm = backData.tag_height
      this.rectData = backData.rectInfo
      // 当前所在的标签编辑页面
      if (this.labelTemplates == 'plank') {
        this.draw(backData.tem_data, true, this.mycanvasId)
      } else {
        this.draw(
          backData?.surplus_data ?? backData?.surplus_tem_data,
          true,
          this.mycanvasId
        )
      }
      // }else{
      //     let deleteObjs = this.mycanvas.getObjects().slice(1)
      //     for(let i in deleteObjs){
      //         this.mycanvas.remove(deleteObjs[i])
      //     }
      //     this.mycanvas.renderAll()
      // }
    },
    /** 删除选中元素 */
    deleteEl(objects) {
      objects = objects || this.currentCanvas.getActiveObjects()
      const allObjects = this.currentCanvas.getObjects()
      // 删除元素内如果存在控制块元素需要删除其源元素
      objects.forEach((object) => {
        if (object.typeName == 'controlRect') {
          objects.push(
            allObjects.find(
              (it) => it.item_id === object.item_id && !isControlRectEl(it)
            )
          )
        }
      })
      objects.forEach((current) => {
        this.deleteCurrentData(current)
      })
      if (!objects?.length) {
        return this.$message({
          message: translate('printPage.editPage.deleteAreaTip'),
          type: 'warning',
          duration: 1000,
        })
      }
      // 删除板件标注蓝框
      this[this.mycanvasId].discardActiveObject()
      this[this.mycanvasId].renderAll()
      // 清空已选中板件标注
      this.currentItem = null
      this.$message({
        message: translate('common.deleteSuccess'),
        type: 'success',
        duration: 1000,
      })
    },
    // 删除板件标注
    deleteCurrentData(current) {
      if (current) {
        this.countOldTem()
        this[this.mycanvasId].remove(current)
        this.clearControlRect()
        this[this.mycanvasId].renderAll()
        // 删除的是板件标注 同时需要删除板件标注框体
        if (this.plankSignBox.length && current.source_data == 'plankSign') {
          let box = this[this.mycanvasId]
            .getObjects()
            .find(
              (klass) =>
                klass.source_data == 'plankSignBox' &&
                klass.item_id == current.item_id
            )
          this[this.mycanvasId].remove(box)
          let index = this.plankSignBox.findIndex(
            (klass) => klass.item_id == current.item_id
          )
          this.plankSignBox.splice(index, 1)
        }
        // 删除主元素 附属元素一同删除
        if (current.auxiliaryEl) {
          current.auxiliaryEl?.forEach((klass) => {
            this[this.mycanvasId].remove(klass)
          })
        }
      }
    },
    changeWordWrap() {
      // 自动换行
      this.countOldTem()
      let current = this.currentItem
      if (current) {
        current.set({
          splitByGrapheme: this.splitByGrapheme,
        })
        this[this.mycanvasId].renderAll()
      }
    },
    handleTextAlignChange(value) {
      // 对齐方式
      this.countOldTem()
      let current = this.currentItem
      if (current) {
        current.set({
          textAlign: value,
        })
        this[this.mycanvasId].renderAll()
      }
    },
    handleDiameterChange(value) {
      this.countOldTem()
      let current = this.currentItem
      if (current) {
        let groupItems = current.getObjects()
        let pValue = mmToPx(value / 2, this.arrDPI)
        groupItems[0].set({
          radius: pValue,
          // left: -pValue,
          // top: -pValue,
          mRadius: value,
        })
        current.set({
          width: groupItems[0].width,
          height: groupItems[0].width,
        })
        this[this.mycanvasId].renderAll()
      }
    },
    handleGraColorChange(value) {
      this.countOldTem()
      let current = this.currentItem
      if (current) {
        current.set({
          fill: value,
        })
      }
      this[this.mycanvasId].renderAll()
    },
    changeAngle(value) {
      this.countOldTem()
      let current = this.currentItem
      if (current) {
        current.set({
          angle: value,
        })
      }
      this[this.mycanvasId].renderAll()
    },
    changeTagSize(type, value) {
      this.countOldTem()
      if (type == 'w') {
        this.tagSizeWPx = mmToPx(value, this.arrDPI)
        this.setTagTimer = setTimeout(() => {
          clearTimeout(this.setTagTimer)
          let rect = this['mycanvas_tag'].getObjects()[0]
          let rect2 = this['mycanvas_surplus'].getObjects()[0]
          rect.set('width', this.tagSizeWPx)
          rect2.set('width', this.tagSizeWPx)
          this['mycanvas_tag'].renderAll()
          this['mycanvas_tag'].item(0).setCoords()
          this['mycanvas_surplus'].renderAll()
          this['mycanvas_surplus'].item(0).setCoords()
        }, 100)
      } else {
        this.tagSizeHPx = mmToPx(value, this.arrDPI)
        this.setTagTimer = setTimeout(() => {
          clearTimeout(this.setTagTimer)
          let rect = this['mycanvas_tag'].getObjects()[0]
          rect.set('height', this.tagSizeHPx)
          this['mycanvas_tag'].renderAll()
          this['mycanvas_tag'].item(0).setCoords()
          let rect2 = this['mycanvas_surplus'].getObjects()[0]
          rect2.set('height', this.tagSizeHPx)
          this['mycanvas_surplus'].renderAll()
          this['mycanvas_surplus'].item(0).setCoords()
        }, 50)
      }
    },
    async chooseTagSet(item) {
      if (this.chooseOtherSet) {
        this.resetData(item.type)
        this.nowChooseSet = item.type
        this.currentItem = null
      }
    },
    /** @desc 设置字体大小 */
    handleFontSizeChange(value, current) {
      const tempValue = Number(this.inputCheck(value))
      this.countOldTem()
      if (current) {
        let px = PtToPx(tempValue, this.arrDPI)
        current.set({
          fontSize: px,
          fontPt: tempValue,
        })
        if (current.source_data == 'plankSign') {
          let box = this[this.mycanvasId]
            .getObjects()
            .find(
              (item) =>
                item.source_data == 'plankSignBox' &&
                item.item_id == current.item_id
            )
          if (box) {
            this[this.mycanvasId].remove(box)
            this.drawAllSignRect()
          }
        }
        if (current.typeName == 'Onecode') {
          // let text = current.getObjects()[1]
          // text.set({
          //     fontSize: PtToPx(value,this.arrDPI),
          //     fontPt:value
          // })
          let img = current.getObjects()[0]
          img.set({
            fontSize: px,
            fontPt: tempValue,
          })
          this.upDateImg(current, this.codeAtt, img)
        } else if (
          current.typeName == 'Typography' ||
          current.typeName == 'SurplusSize'
        ) {
          let items = current.getObjects()
          const openKlass = items.filter((klass) => klass.uniqueID)
          items = items.filter((klass) => !klass.uniqueID)
          const oldW = items[1].width
          const oldH = items[1].height / 2
          items.forEach((item, index) => {
            if (index) {
              item.set({
                fontSize: px,
                fontPt: tempValue,
              })
            }
          })
          openKlass.forEach((klass) => {
            klass.set({
              left: klass.left - oldW + items[1].width,
              top: klass.top - oldH + items[1].height / 2,
            })
          })
        } else if (
          current.typeName == 'wardrobeNum' ||
          current.typeName == 'Marking'
        ) {
          let textItem = current.getObjects()[1]
          textItem.set({
            fontSize: px,
            fontPt: tempValue,
          })
        } else if (current.typeName == 'holeSlotPicture') {
          current.auxiliaryEl?.forEach((klass) => {
            klass.fontF({ fontSize: px, fontPt: tempValue })
          })
        } else if (current.typeName === 'Table') {
          this.handleChangeTableSize(value, current)
        }
      }
      this.changeKlassControlRect(current)
      this[this.mycanvasId].renderAll()
    },
    inputCheck(value) {
      let reg = /[^\d]/
      if (reg.test(value) || value < 4 || value > 32) {
        this.inputAlert.fontSizeAlert = '请输入4-32的整数'
        this.nowFontSize = 8
        value = 8
      } else {
        this.inputAlert.fontSizeAlert = ''
      }
      return value
    },
    handleFontFamilyChange(value, current) {
      if (current) {
        current.set({
          fontFamily: value,
        })
        if (current.typeName == 'Onecode') {
          let img = current.getObjects()[0]
          img.set({
            fontFamily: value,
          })
          this.upDateImg(current, this.codeAtt, img)
        } else if (
          current.typeName == 'Typography' ||
          current.typeName == 'SurplusSize'
        ) {
          let items = current.getObjects()
          items = items.filter((klass) => !klass.uniqueID) // 开门方向的uniqueID是 showDoor
          items.forEach((item, index) => {
            if (index) {
              item.set({
                fontFamily: value,
              })
            }
          })
        } else if (
          current.typeName == 'wardrobeNum' ||
          current.typeName == 'Marking'
        ) {
          let textItem = current.getObjects()[1]
          textItem.set({
            fontFamily: value,
          })
        } else if (current.typeName == 'holeSlotPicture') {
          current.auxiliaryEl?.forEach((klass) => {
            klass.fontF({ fontFamily: value })
          })
        } else if (current.typeName == 'Table') {
          this.handleChangeTableSize(this.nowFontSize, current)
        }
      }
      this[this.mycanvasId].renderAll()
    },
    /** @desc 改变文本字体加粗 */
    changeFontWeight(current) {
      this.countOldTem()
      if (current) {
        if (current.typeName == 'Onecode') {
          // let text = current.getObjects()[1]
          // text.set({
          //     fontWeight:"bold"
          // })
          let img = current.getObjects()[0]
          img.set({
            fontWeight: this.fontWeight ? 'bold' : '',
          })
          this.upDateImg(current, this.codeAtt, img)
        } else if (
          current.typeName == 'Typography' ||
          current.typeName == 'SurplusSize'
        ) {
          let items = current.getObjects()
          items.forEach((e, i) => {
            if (i) {
              e.set({
                fontWeight: this.fontWeight ? 'bold' : '',
              })
            }
          })
        } else if (
          current.typeName == 'wardrobeNum' ||
          current.typeName == 'Marking'
        ) {
          let item = current.getObjects()[1]
          item.set({
            fontWeight: this.fontWeight ? 'bold' : '',
          })
        } else if (current.typeName == 'holeSlotPicture') {
          current.auxiliaryEl?.forEach((klass) => {
            klass.fontF({ fontWeight: this.fontWeight ? 'bold' : '' })
          })
        } else {
          current.set({
            fontWeight: this.fontWeight ? 'bold' : '',
          })
        }
      }
      this[this.mycanvasId].renderAll()
    },
    // 90°旋转
    changePlankRotate(deg) {
      this.countOldTem()
      let current = this.currentItem
      current.rotate(deg)
      current.set({
        plankRotate: this.plankRotate,
        rotateDeg: deg,
      })
      this[this.mycanvasId].renderAll()
    },
    // 显示开门方向
    changeShowOpenDoor() {
      this.countOldTem()
      let current = this.currentItem
      const klassArr = current.getObjects()
      if (klassArr.length) {
        const openDoor = klassArr.filter(
          (klass) => klass.uniqueID == 'showDoor'
        )
        openDoor.forEach((klass) => {
          klass.set({
            opacity: Number(this.showOpenDoor),
          })
        })
      }
      current.set({
        showOpenDoor: this.showOpenDoor,
      })
      this[this.mycanvasId].renderAll()
    },
    // 显示封边信息
    changeShowEdgeOff() {
      this.countOldTem()
      let current = this.currentItem
      current.set({
        showEdgeOff: this.showEdgeOff,
      })
    },
    changeShowEdgeDisplay() {
      this.countOldTem()
      let current = this.currentItem
      current.set({
        showEdgeDisplay: this.showEdgeDisplay,
      })
      this[this.mycanvasId].renderAll()
    },
    // 是否有小数点位数限制
    changeSizeRestrict() {
      this.countOldTem()
      let current = this.currentItem
      if (current) {
        if (
          current.source_data == 'oSize' ||
          current.source_data == 'cutSize'
        ) {
          current.set({
            sizeRestrict: this.sizeRestrict,
          })
        }
      }
    },
    /** 小数点位数限制调整 */
    changeSizeRestrictNum(num) {
      this.countOldTem()
      this.currentItem?.set({
        sizeRestrictNum: num,
      })
    },
    changeGroupTextAttribute(klassArr, data, key = null, otherData = {}) {
      let d = klassArr
      if (key) {
        d = klassArr.filter((klass) => klass[key])
      }
      d.forEach((klass) => {
        klass.set(Object.assign(data, otherData))
      })
    },
    // 切换显示孔槽状态
    changeIsHole(key) {
      this.countOldTem()
      const current = this.currentItem
      current.set({
        [key]: this[key],
      })
    },
    // 切换显示大板尺寸状态
    changePlankSize() {
      this.countOldTem()
      const current = this.currentItem
      current.auxiliaryEl?.forEach((klass) => {
        klass.showF()
      })
      this[this.mycanvasId].renderAll()
    },
    handleLineSpaceChange(value) {
      this.countOldTem()
      let current = this.currentItem
      if (current) {
        if (current.typeName == 'Onecode') {
          let img = current.getObjects()[0]
          this.upDateImg(current, this.codeAtt, img)
          img.set({
            lineHeight: value,
          })
        } else {
          current.set({
            lineHeight: value,
          })
        }
      }
      this[this.mycanvasId].renderAll()
    },
    handleCodeRotateDegChange(deg) {
      const oriLeft = this.currentItem.left
      const oriTop = this.currentItem.top
      this.currentItem.rotate(deg)
      this.currentItem.set({
        rotateDeg: deg,
        oriLeft,
        oriTop,
      })
      this[this.mycanvasId].renderAll()
    },
    handleCodeAttChange(value) {
      let current = this.currentItem
      if (value == 'EAN13') {
        this.fontSizeOpeion = [4, 6, 8]
      } else {
        this.fontSizeOpeion = [4, 6, 8, 10, 12, 14, 16, 20, 26, 32]
      }
      if (current) {
        let img = current.getObjects()[0]
        img.set({
          code_type: value,
        })
        this.upDateImg(current, this.codeAtt, img)
      }
    },
    // 切换数据信息报错 报错原因：余料的数据信息还未从接口获取
    handleDataSourcesChange(value) {
      // 触发事件导致报错，需要获取this.nowDataSources的数据
      this.countOldTem()
      let current = this.currentItem
      if (current) {
        let text = this.nowDataSources.filter((item) => {
          if (item.value == value) {
            return item
          }
        })[0].text
        let textC = new fabric.Text(text, {
          fontSize: current.fontSize,
        })
        const { text: str, fontSize } = dealTextStr(
          text,
          current.fontSize,
          current.rectWidth ?? textC.width,
          current.rectHeight ?? textC.height,
          this.isAutoFontSize
        )
        current.set({
          text: str,
          source_data: value,
          fontPt: Math.ceil(PxToPt(fontSize)),
          fontSize: fontSize,
        })
        // 同步一下标签上的小数位数限制
        this.sizeRestrict = current.sizeRestrict
        // 每次切换为板件标注时添加， 不是则查看是否是板件标注切换的，是则删除数组中存在的板件标注并重新绘制框体
        if (current.source_data == 'plankSign') {
          this.plankSignBox.push(current)
          this.drawAllSignRect()
        } else {
          let index = this.plankSignBox.findIndex(
            (klass) => klass.item_id == current.item_id
          )
          if (index !== -1) {
            let box = this[this.mycanvasId]
              .getObjects()
              .find(
                (klass) =>
                  klass.source_data == 'plankSignBox' &&
                  klass.item_id == this.plankSignBox[index].item_id
              )
            if (box) {
              this[this.mycanvasId].remove(box)
            }
            this.plankSignBox.splice(index, 1)
          }
        }
      }
      this[this.mycanvasId].renderAll()
    },
    // 绘制单个板件标注的框体
    drawSignRect(current) {
      const { top, left, width, height } = current
      let rect = new fabric.Rect({
        top: top - pxTomm(30, this.arrDPI) / 2,
        left: left - pxTomm(30, this.arrDPI) / 2,
        width: width + pxTomm(30, this.arrDPI),
        stroke: 1,
        fill: 'transparent',
        height: height + pxTomm(30, this.arrDPI),
        source_data: 'plankSignBox',
        typeName: 'DataSource',
        item_id: current.item_id,
        hasControls: false,
      })
      this[this.mycanvasId].add(rect)
      this[this.mycanvasId].setActiveObject(rect)
    },
    // 重绘所有板件标注
    drawAllSignRect() {
      this[this.mycanvasId]
        .getObjects()
        .filter((klass) => klass.source_data == 'plankSignBox')
        .forEach((klass) => this[this.mycanvasId].remove(klass))
      this.plankSignBox.forEach((klass) => {
        this[this.mycanvasId].remove(klass)
        this[this.mycanvasId].add(klass)
        this.drawSignRect(klass)
      })
    },
    changeCodeShowValue(value) {
      let current = this.currentItem
      if (current) {
        current.set({
          showCode: value,
        })
      }
    },
    handleCodeChange(value) {
      this.countOldTem()
      // 设置条形码数据源
      let current = this.currentItem
      if (this.nowChooseSet == 'Onecode') {
        let activs = current.getObjects()
        let img = activs[0]
        // let text = activs[1]
        if (['plankNum', 'oriPlankNum', 'remarks', 'ncName'].includes(value)) {
          this.codeAttOpeion = mockData.codeAttOpeion
          this.codeAtt = 'EAN13'
          this.upDateImg(current, this.codeAtt, img)
          img.set({
            source_data: value,
            code_type: 'EAN13',
          })
          // text.set({
          //     text:'6160479583801'
          // })
        } else {
          this.codeAttOpeion = mockData.codeAttOpeionK
          this.codeAtt = 'CODE128'
          this.upDateImg(current, this.codeAtt, img)
          img.set({
            source_data: value,
            code_type: 'CODE128',
          })
          // text.set({
          //     text:'6160479583801K'
          // })
        }
      } else {
        current.set({
          source_data: value,
        })
      }

      this[this.mycanvasId].renderAll()
    },
    /**
     * 选择特殊标记符号 绘制对应图形
     * @param {Object} current 当前选中的组件
     * @param {String} type 当前绘制类型 init：初始化时绘制，handler： 操作时绘制
     * @return
     * @author zhouchi 2024-07-16
     * */
    selectSpecialMark(current = {}, type) {
      if (current?.opacity && type === 'init')
        this.inputOpacity = current.opacity
      else this.inputOpacity = 0.25
      const defaultData = {
        width: 10,
        height: 10,
      }
      let specialMarkW = mmToPx(defaultData.width, this.arrDPI)
      let specialMarkH = mmToPx(defaultData.height, this.arrDPI)
      let specialMarkOx = current?.left ?? this.overUpPoint.x
      let specialMarkoy = current?.top ?? this.overUpPoint.y
      let pointArr1 = [
        {
          x: specialMarkOx,
          y: specialMarkoy + specialMarkH, //1
        },
        {
          x: specialMarkOx + specialMarkW / 2, //2
          y: specialMarkoy,
        },
        {
          x: specialMarkOx + specialMarkW,
          y: specialMarkoy + specialMarkH, //3
        },
      ]
      const options =
        type === 'init'
          ? {
              top: current.top,
              left: current.left,
              hasControls: current.hasControls,
              typeName: current.typeName,
              item_id: current.item_id,
              angle: current.angle,
              fill: current.fill,
              originX: 'center',
              originY: 'center',
              mWidth: current.mWidth,
              mHeight: current.mHeight,
              scaleX: current.scaleX,
              scaleY: current.scaleY,
              opacity: current.opacity,
            }
          : {
              top: specialMarkoy,
              left: specialMarkOx,
              opacity: this.inputOpacity,
              typeName: 'specialMark',
              hasControls: true,
              originX: 'center',
              originY: 'center',
            }

      Promise.resolve().then(() => {
        setTimeout(() => {
          let specialMark = new fabric.Polygon(pointArr1, options)
          // 禁止旋转
          this.setItemId(specialMark)
          specialMark.setControlVisible('mtr', false)
          this.currentItem = specialMark
          this[this.mycanvasId].add(specialMark)
          this.getKclassValueToAttr(specialMark)
        })
      })
    },
    /**
     * 选择板件标记（不包括特殊标记符号）
     * @param {Object} current 当前选中的组件
     * * @return
     * @author zhouchi 2024-07-16
     * */
    selectWardrobeNum(current) {
      const position = {
        radius: this.nowDiameter,
        fontSize: this.nowFontSize,
        left: current?.left ?? this.overUpPoint.x,
        top: current?.top ?? this.overUpPoint.y,
        fontFamily: this.nowFontFamily,
        fontWeight: this.fontWeight ? 'bold' : '',
        roomSortWay: this.roomSortWay,
      }
      this.drawOrderOrPanel(this.partSignValue, position, 'wardrobeNum')
    },
    // 板件标记变化，由于需要兼容之前数据 所以还是采用的wardrobeNum作为标识，wardrobeNum现代表partSign
    handlePartSign(value) {
      let current = this.currentItem
      let fieldValue = ''
      let textValue = ''

      if (value === 'specialMark') {
        this.temp = current
        //移除板件标记
        this[this.mycanvasId].remove(current)
        this.selectSpecialMark(current, 'handler')
        return
      }
      current = this.currentItem

      if (this.currentItem.typeName === 'specialMark') {
        this[this.mycanvasId].remove(current)
        this.selectWardrobeNum(current)
        this[this.mycanvasId].setActiveObject(this.currentItem)
      }
      const text = this.currentItem?.getObjects?.()[1]
      const group = this.currentItem?.getObjects?.()[0]
      const radius = group.mRadius
      //柜体序号
      if (value === 'wardrobeNum') {
        textValue = '1'
        fieldValue = 'wardrobeNum'
      } else if (value === 'isFrontPlank') {
        //面板标记
        textValue = '面'
        fieldValue = 'isFrontPlank'
      } else {
        // 选择了按房间排序后，由于内容较多，因此判断如果当前的圆框直径是4的话，就重新赋值为6
        if (radius === 4) {
          this.nowDiameter = 6
          this.handleDiameterChange(6)
        }
        // 柜体序号，按房间排序，默认按字母排序
        textValue = 'A-1'
        this.roomSortWay = 'letters'
        fieldValue = `wardrobeNumToRoom`
      }

      this.currentItem.set({
        field: fieldValue,
      })
      text.set({
        text: textValue,
        roomSortWay: this.roomSortWay,
      })
      this[this.mycanvasId].renderAll()
    },
    handleTableChange(value) {
      const { top, left, fontSize, fontPt } = this.currentItem
      this[this.mycanvasId].remove(this.currentItem)
      const tableUrl = fabricTable(
        this[this.mycanvasId],
        true,
        false,
        fontSize * 2,
        value
      )
      fabric.Image.fromURL(tableUrl, (img) => {
        img.set({
          top,
          left,
          hasControls: false,
          source_data: value,
          fontSize,
          fontPt,
          typeName: 'Table',
          mWidth: img.width,
          mHeight: img.height,
          // pxWidth: mmToPx(this.codeW, this.arrDPI),
          // pxHeight: mmToPx(this.codeH, this.arrDPI),
          showCode: true,
          scaleX: 0.5,
          scaleY: 0.5,
        })
        this.setItemId(img)
        this[this.mycanvasId].add(img)
        this.currentItem = img
        this[this.mycanvasId].setActiveObject(this.currentItem)
      })
    },
    handleChangeTableSize(value, current) {
      let tempValue = value
      tempValue = Number(this.inputCheck(tempValue))
      const { source_data, fontFamily } = current
      const { left, top } = getPositionByCanvas(current)
      let px = PtToPx(tempValue, this.arrDPI)
      this[this.mycanvasId].remove(current)
      const tableUrl = fabricTable(
        this[this.mycanvasId],
        true,
        false,
        px * 2,
        source_data,
        10,
        10,
        fontFamily
      )
      fabric.Image.fromURL(tableUrl, (img) => {
        img.set({
          top,
          left,
          hasControls: true,
          source_data,
          fontSize: px,
          fontPt: tempValue,
          typeName: 'Table',
          mWidth: img.width,
          mHeight: img.height,
          // pxWidth: mmToPx(this.codeW, this.arrDPI),
          // pxHeight: mmToPx(this.codeH, this.arrDPI),
          showCode: true,
          scaleX: 0.5,
          scaleY: 0.5,
          fontFamily,
        })
        img.setControlVisible('mtr', false)
        this.setItemId(img)
        this[this.mycanvasId].add(img)
        const instance = this.getCurrentUseSelectedControl()
        // 因为这里要重建选区，需要提前存储一份活跃对象(麻啦)
        instance?.addActiveToPre()
        this.currentCanvas.discardActiveObject()
        this.currentCanvas.renderAll()
        // 避免在多选的情况下取消掉了多选的选中
        if (this.activeElementCount === 1) {
          this[this.mycanvasId].setActiveObject(this.currentItem)
          this.currentItem = img
        } else {
          // 执行两次因为取活跃对象是取的倒数第二个详情可以查看addObjectToSelected的实现
          instance?.addActiveToPre([img])
          instance?.addActiveToPre()
          // 多选状态下将新生成的元素添加到选中内
          instance?.addObjectToSelected(img)
        }
      })
    },
    changePictureScale(e, step) {
      if (step) {
        this.pictureScale = parseFloat((step + this.pictureScale).toFixed(2))
      }
      this.countOldTem()
      const current = this.currentItem
      current.set({
        mWidth: 19 * this.pictureScale,
        mHeight: 19 * this.pictureScale,
        defaultScale: this.pictureScale,
      })
      current.set({
        scaleX: mmToPx(19 * this.pictureScale, this.arrDPI) / current.width,
        scaleY: mmToPx(19 * this.pictureScale, this.arrDPI) / current.height,
      })
      if (current.auxiliaryEl) {
        current.auxiliaryEl.forEach((klass) => {
          klass.moveF(current)
        })
      }
      this[this.mycanvasId].renderAll()
    },
    /**
     * 设置特殊标记符号的透明度和大小
     * @params {Number} value 滑动条的值
     * @params {String} type  觉得当前操作的时大小 or 透明度
     * @return
     * @author zhouchi 2024-07-16
     */
    changeOpacityOrScaleSize(value, type) {
      const current = this.currentItem
      if (type === 'scale') {
        current.set({
          scaleX: value,
          scaleY: value,
        })
      } else {
        current.set({
          opacity: value,
        })
      }
      this[this.mycanvasId].renderAll()
    },
    changeImgSize(type = 'w', value) {
      if (!value) {
        if (type == 'w') {
          this.codeW = 1
        } else {
          this.codeH = 1
        }
        value = 1
      }
      this.countOldTem()
      let current = this.currentItem
      let px = mmToPx(value, this.arrDPI)
      if (current) {
        if (this.nowChooseSet == 'QRcode') {
          this.codeW = value
          this.codeH = value
          current.set({
            mWidth: value,
            mHeight: value,
          })
          this.imgScaleX(current, mmToPx(value, this.arrDPI), current.width)
          this.imgScaleY(current, mmToPx(value, this.arrDPI), current.height)
        } else if (this.nowChooseSet == 'Onecode') {
          let img = current.item(0)
          switch (type) {
            case 'w':
              current.set({
                mWidth: value,
                width: mmToPx(value, this.arrDPI),
              })
              img.set({
                mWidth: value,
                width: mmToPx(value, this.arrDPI),
              })
              this.upDateImg(current, this.codeAtt, img, 'size')
              // this.imgScaleX(current,mmToPx(value,this.arrDPI), current.width)
              break
            case 'h':
              // this.imgScaleY(current,mmToPx(value,this.arrDPI), current.height)
              current.set({
                mHeight: value,
                height: mmToPx(value, this.arrDPI),
              })
              this.upDateImg(current, this.codeAtt, img, 'size')
              break
          }
          img.set({
            left: -mmToPx(this.codeW, this.arrDPI) / 2,
            top: -mmToPx(this.codeH, this.arrDPI) / 2,
          })
        } else {
          switch (type) {
            case 'w':
              current.set({
                mWidth: value,
              })
              this.imgScaleX(current, mmToPx(value, this.arrDPI), current.width)
              break
            case 'h':
              this.imgScaleY(
                current,
                mmToPx(value, this.arrDPI),
                current.height
              )
              current.set({
                mHeight: value,
              })
              break
          }
        }
      }
      this[this.mycanvasId].renderAll()
    },
    setItemId(item) {
      this.currentItem = item
      if (item.item_id) return
      item.set({ item_id: nanoid() })
      // this.item_id += 1
    },
    resetData(type) {
      // 画不同组件数据重置不同
      switch (type) {
        case 'Typography':
          this.codeW = 15
          this.codeH = 25
          this.showEdgeOff = false
          this.showEdgeDisplay = false
          this.showOpenDoor = true
          this.plankRotate = false
          break
        case 'Onecode':
          this.codeW = 35
          this.codeH = 10
          this.nowCodeInfo =
            this.labelTemplates == 'plank' ? 'plankNum' : 'oriPlankNum'
          break
        case 'QRcode':
          this.codeW = 10
          this.codeH = 10
          this.nowCodeInfo =
            this.labelTemplates == 'plank' ? 'ggid' : 'restockingCode'
          this.QRCodeFormat = 'QRCode'
          break
        case 'DataSource':
          this.nowDataInfo = ''
          break
        case 'Graphics':
          this.codeW = 10
          this.codeH = 10
          this.GraColor = '#000000'
          this.angle = 0
          break
        case 'wardrobeNum':
          this.nowDiameter = 4
          this.partSignValue = 'wardrobeNum'
          break
        case 'holeSlotPicture':
          this.codeW = 19
          this.codeH = 19
          this.pictureScale = 1
          break
        case 'Marking':
          this.nowDiameter = 10
          break
        case 'SurplusSize':
          this.codeW = 12
          this.codeH = 15
          break
        default:
          break
      }
      this.splitByGrapheme = false
      this.textAlign = 'left'
      this.fontWeight = type !== 'Marking' ? false : true
      this.nowFontSize = type == 'Marking' ? 10 : 8
      this.lineSpace = 1
      this.nowFontFamily = '微软雅黑'
    },
    imgScaleX(img, imgW, oImgW) {
      let scaleX = imgW / oImgW
      img.set({
        scaleX: scaleX,
      })
    },
    imgScaleY(img, imgH, oImgH) {
      let scaleY = imgH / oImgH
      img.set({
        scaleY: scaleY,
      })
    },
    draw(data = null, update = false, canvasId) {
      if (!canvasId) {
        canvasId = 'mycanvas_tag'
      }
      this.mycanvasId = canvasId
      // 如果canvasId的值不存在，表示是从mounted进入的，此时需要
      // 绘制的时候当前需要绘制的对象
      let parentDIV = document.getElementsByClassName('edit_tagTem_mid')[0]
      let width = parentDIV.clientWidth
      let height = parentDIV.clientHeight
      // 判断当前是添加一个模板还是更新一个模板
      if (!update) {
        let mycanvas = new fabric.Canvas(canvasId, {
          width: width,
          height: height,
          selections: false,
          // 允许右键点击(如不开启则在点击时无法判断到底是右键还是左键)
          fireRightClick: true,
        })
        this[canvasId] = mycanvas
        // 没实现出现在顶层
        this[canvasId].preserveObjectStacking = false // 选择画布中的对象时，该对象出现在顶层
        // 创建事件监听
        this.addFabricCanvasListener()
      }
      if (data) {
        this.tagSizeWPx = mmToPx(this.tagSizeWmm, this.arrDPI)
        this.tagSizeHPx = mmToPx(this.tagSizeHmm, this.arrDPI)
        // 标签框 整个标签
        let rect = new fabric.Rect({
          width: this.tagSizeWPx,
          height: this.tagSizeHPx,
          fill: '#fff',
          top: this.rectData.top,
          left: this.rectData.left,
          typeName: 'TageBox',
          selectable: false,
        })
        rect.hasControls = false // 只能移动不能（编辑）操作
        this[canvasId].add(rect)
      } else {
        this.tagSizeWPx = mmToPx(this.tagSizeWmm, this.arrDPI)
        this.tagSizeHPx = mmToPx(this.tagSizeHmm, this.arrDPI)
        // 标签框
        let rect = new fabric.Rect({
          width: this.tagSizeWPx,
          height: this.tagSizeHPx,
          fill: '#fff',
          top: (height - this.tagSizeHPx) / 2,
          left: (width - this.tagSizeWPx) / 2,
          typeName: 'TageBox',
          selectable: false,
        })
        rect.hasControls = false
        this[canvasId].add(rect)
      }
      this.drawItemTem(data, true, canvasId)
      if (!update) {
        // 移动画布位置
        this.moveFabricCanvasToCenter()
      }
    },
    // init canvas
    drawItemTem(data, checkOne = false, canvasId) {
      data.forEach((item, index) => {
        let drawD = item.data
        switch (item.type) {
          case 'holeSlotPicture':
            // eslint-disable-next-line no-case-declarations
            const uniqueID = item.data.uniqueID
            // eslint-disable-next-line no-case-declarations
            const auxiliaryEl = data.filter((item) => {
              const d = item.data
              return (
                d?.uniqueID == uniqueID && item.type == 'holeSlotPicture-size'
              )
            })
            if (!auxiliaryEl) return
            fabric.Image.fromURL(
              require('../../assets/hole_slot.png'),
              (img) => {
                img.set({
                  typeName: item.type,
                  ...drawD,
                })
                const auxiliaryS = []
                auxiliaryEl.forEach((item) => {
                  const draw = item.data
                  const text = new fabric.Text(draw.text, {
                    typeName: item.type,
                    ...draw,
                    fontFamily: draw.fontFamily ?? '微软雅黑',
                  })
                  text.set({
                    opacity: Number(draw.isShow),
                  })
                  // 注入方法
                  this.klassSetMethods(text, { show: 'plankSize' })
                  this[canvasId].add(text)
                  auxiliaryS.push(text)
                })
                img.set({
                  auxiliaryEl: auxiliaryS,
                })
                this[canvasId].add(img)
              }
            )
            break
          case 'FixedText':
            // eslint-disable-next-line no-case-declarations
            let iText = new fabric.Textbox(drawD.text, {
              fontPt: drawD.fontPt,
              fontSize: drawD.fontSize,
              fontWeight: drawD.fontWeight,
              lineHeight: drawD.lineHeight,
              top: drawD.top,
              left: drawD.left,
              typeName: item.type,
              item_id: drawD.item_id,
              hasControls: false,
              lockScalingY: false,
              lockRotation: true,
              lockScalingFlip: false,
              splitByGrapheme: drawD.splitByGrapheme
                ? drawD.splitByGrapheme
                : false,
              objectCaching: false,
              textAlign: drawD.textAlign ? drawD.textAlign : 'left',
              fontFamily: drawD.fontFamily ?? '微软雅黑',
            })
            this.setControlVisible(iText, false, 'mtr')
            // 兼容老数据
            if (drawD.width && drawD.height) {
              iText.set({
                width: drawD.width,
                height: drawD.height,
              })
            }
            this[canvasId].add(iText)
            break
          case 'DataSource':
            // 字段用错了
            if (drawD.source_data == 'edgeInfo') {
              drawD.source_data = 'sholeInfo'
            }
            if (drawD.source_data == 'name') {
              drawD.source_data = 'partName'
            }
            // 不对板件标注的框体进行绘制，后续统一绘制
            if (item.data.source_data == 'plankSignBox') {
              break
            }

            // eslint-disable-next-line no-case-declarations
            let textC = new fabric.Text(drawD.text, {
              fontSize: drawD.fontSize,
            })
            // eslint-disable-next-line no-case-declarations
            const { text, fontSize } = dealTextStr(
              drawD.text,
              drawD.fontSize,
              drawD.rectWidth ?? textC.width,
              drawD.rectHeight ?? textC.height,
              drawD.isAutoFontSize
            )
            // eslint-disable-next-line no-case-declarations
            let Text = new fabric.Text(text, {
              fontPt: drawD.fontPt,
              fontSize: fontSize,
              fontWeight: drawD.fontWeight,
              lineHeight: drawD.lineHeight,
              top: drawD.top,
              left: drawD.left,
              width: drawD.width,
              height: drawD.height,
              rectHeight: drawD.rectHeight ?? 11.3,
              rectWidth: drawD.rectWidth ?? 80,
              maxWidth: drawD.width,
              isSymmetricSwapping: true,
              textWrap: 'word',
              hasControls: false,
              typeName: item.type,
              item_id: drawD.item_id,
              source_data: drawD.source_data,
              textBackgroundColor: drawD.textBackgroundColor,
              sizeRestrict: drawD.sizeRestrict ? drawD.sizeRestrict : undefined,
              sizeRestrictNum: drawD.sizeRestrictNum ?? 2,
              isAutoFontSize: drawD.isAutoFontSize ?? true,
              fontFamily: drawD.fontFamily ?? '微软雅黑',
              // 统计维度默认值为不限
              statisticsDimension: drawD.statisticsDimension ?? 'noLimit',
            })
            Text.setControlVisible('mtr', false)
            // 保存所有的板件标注
            if (drawD.source_data == 'plankSign') {
              this.plankSignBox.push(Text)
            }
            this.nowFontSize = fontSize
            this.inputAlert.fontSizeAlert = ''
            this[canvasId].add(Text)
            break
          case 'Graphics':
            // eslint-disable-next-line no-case-declarations
            let Graphics = new fabric.Polygon(drawD.points, {
              top: drawD.top,
              left: drawD.left,
              hasControls: drawD.hasControls,
              typeName: item.type,
              item_id: drawD.item_id,
              angle: drawD.angle,
              fill: drawD.fill,
              originX: 'center',
              originY: 'center',
              mWidth: drawD.mWidth,
              mHeight: drawD.mHeight,
              scaleX: drawD.scaleX,
              scaleY: drawD.scaleY,
            })
            Graphics.setControlVisible('mtr', false)
            this[canvasId].add(Graphics)
            break
          case 'QRcode':
            fabric.Image.fromURL(
              require('../../assets/tag_tem_test.png'),
              (img) => {
                img.set({
                  top: drawD.top,
                  left: drawD.left,
                  hasControls: drawD.hasControls,
                  typeName: item.type,
                  item_id: drawD.item_id,
                  width: drawD.width,
                  height: drawD.height,
                  source_data: drawD.source_data,
                  mWidth: drawD.mWidth,
                  mHeight: drawD.mHeight,
                  scaleX: drawD.scaleX,
                  scaleY: drawD.scaleY,
                  showCode: drawD.showCode,
                  isQRcodeNCSuffix: drawD.isQRcodeNCSuffix ?? true,
                  QRCodeFormat: drawD.QRCodeFormat ?? 'QRCode',
                })
                this[canvasId].add(img)
              }
            )
            break
          case 'Onecode':
            // eslint-disable-next-line no-case-declarations
            let option = {
              source_data: drawD.source_data,
              codeType: drawD.code_type,
              fontWeight: drawD.fontWeight,
              fontPt: drawD.fontPt,
              lineHeight: drawD.lineHeight,
              scaleX: drawD.Xscale ?? 1,
              scaleY: drawD.Yscale ?? 1,
              fontFamily: drawD.fontFamily ?? '微软雅黑',
            }
            // eslint-disable-next-line no-case-declarations
            let url = this.createDefaultUrl(option)
            fabric.Image.fromURL(url, (img) => {
              img.set({
                source_data: drawD.source_data,
                code_type: drawD.code_type,
                fontPt: drawD.fontPt,
                fontSize: drawD.fontSize,
                lineHeight: drawD.lineHeight,
                fontWeight: drawD.fontWeight,
                fontFamily: drawD.fontFamily ?? '微软雅黑',
                // width: mmToPx(drawD.mWidth, this.arrDPI),
                // height: mmToPx(drawD.mHeight, this.arrDPI),
              })
              img.scaleToWidth(drawD.width * (drawD.Xscale ?? 1))
              img.scaleToHeight(drawD.height * (drawD.Yscale ?? 1))
              // let textValue = drawD.source_data == 'plankNum'?'6160479583801':'6160479583801K'
              // let oText = new fabric.Text(textValue,{
              //     top:25,
              //     fontPt:drawD.fontPt,
              //     fontSize:drawD.fontSize,
              //     lineHeight:drawD.lineHeight,
              //     fontWeight:drawD.fontWeight,
              // })
              let group = new fabric.Group([img], {
                typeName: item.type,
                hasControls: true,
                top: drawD.top,
                left: drawD.left,
                width: drawD.width * (drawD.Xscale ?? 1),
                height: drawD.height * (drawD.Yscale ?? 1),
                mWidth: drawD.mWidth,
                mHeight: drawD.mHeight,
                item_id: drawD.item_id,
                showCode: drawD.showCode,
                Xscale: drawD.Xscale,
                Yscale: drawD.Yscale,
                rotateDeg: drawD.rotateDeg,
                isOnecodeNCSuffix: drawD.isOnecodeNCSuffix ?? true,
              })
              this[canvasId].add(group)
              this[canvasId].bringToFront(group)
              group.set({
                Xscale: 1,
                Yscale: 1,
              })
              group.setControlVisible('mtr', false)
              group.setControlVisible('ml', false)
              group.setControlVisible('mr', false)
              if (drawD.rotateDeg) {
                this.codeRotateDeg = drawD.rotateDeg
                group.rotate(drawD.rotateDeg)
              }
            })
            break
          case 'SurplusSize':
          case 'Typography':
            let imgUrl =
              item.type == 'Typography'
                ? 'paiban_test.png'
                : 'surplus_plank.png'
            fabric.Image.fromURL(require(`../../assets/${imgUrl}`), (img) => {
              let mWidth = item.type == 'Typography' ? 15 : 12
              let mHeight = item.type == 'Typography' ? 25 : 15
              img.set({
                left: drawD.imgItem.left,
                top: drawD.imgItem.top,
                mWidth: mWidth,
                mHeight: mHeight,
              })
              this.imgScaleY(img, mmToPx(mHeight, this.arrDPI) - 10, img.height)
              this.imgScaleX(img, mmToPx(mWidth, this.arrDPI) - 10, img.width)
              let textItems = []
              let surplusSizeInfo = {
                RE: 977,
                BE: 762,
              }
              let textS = ''
              drawD.textItems.forEach((e) => {
                if (
                  item.type == 'SurplusSize' &&
                  canvasId == 'mycanvas_surplus'
                ) {
                  textS = surplusSizeInfo[e.positionName] ?? ''
                }
                let text = new fabric.Text(
                  canvasId == 'mycanvas_tag' ? '0.5' : String(textS),
                  {
                    positionName: e.positionName,
                    fontSize: e.fontSize,
                    fontPt: e.fontPt,
                    fontWeight: e.fontWeight,
                    fontFamily: e.fontFamily ?? '微软雅黑',
                  }
                )
                text.set({
                  top: e.top,
                  left: e.left,
                  oTop: e.top,
                  oLeft: e.left,
                })
                textItems.push(text)
              })
              // 根据板件封边信息位置重新绘制新的开门方向
              let openL = textItems[2]?.left ?? 0
              let openT = textItems[2]?.top ?? 0
              const offsetLeft = (textItems[2]?.width ?? 0) + 6
              const offsetTop = (textItems[2]?.height ?? 0) / 2
              let circle
              if (item.type == 'Typography') {
                circle = this.drawCircle(6, 6, {
                  left: openL + offsetLeft,
                  top: openT + offsetTop,
                  uniqueID: 'showDoor',
                  opacity: Number(drawD.showOpenDoor ?? true),
                })
              }
              const text = this.drawText(
                canvasId == 'mycanvas_tag' ? '开' : '',
                openT + offsetTop,
                openL + offsetLeft,
                8,
                12,
                '',
                {
                  uniqueID: 'showDoor',
                  opacity: Number(drawD.showOpenDoor ?? true),
                }
              )
              let Group = new fabric.Group(
                canvasId == 'mycanvas_tag'
                  ? [img].concat(textItems, circle, text)
                  : [img].concat(textItems, text),
                {
                  typeName: item.type,
                  showEdgeOff: drawD.showEdgeOff ?? false,
                  showEdgeDisplay: drawD.showEdgeDisplay ?? false,
                  showOpenDoor: drawD.showOpenDoor ?? true,
                  plankRotate: drawD.plankRotate ?? false,
                  ...drawD,
                }
              )

              if (drawD.rotateDeg || drawD.rotateDeg == 0) {
                Group.rotate(drawD.rotateDeg)
                this.rotateDeg = drawD.rotateDeg
              } else {
                if (drawD.plankRotate) {
                  Group.rotate(-90)
                  this.rotateDeg = 270
                } else {
                  this.rotateDeg = 0
                }
              }
              // this.imgScaleX(Group, mmToPx(mWidth, this.arrDPI), Group.width)
              // this.imgScaleY(Group, mmToPx(mHeight, this.arrDPI), Group.height)
              this[canvasId].add(Group)
            })
            break
          case 'wardrobeNum':
            const position = {
              radius: drawD.mRadius,
              fontSize: drawD.fontPt,
              left: drawD.left,
              top: drawD.top,
              fontWeight: drawD.fontWeight,
              fontFamily: drawD.fontFamily ?? '微软雅黑',
              roomSortWay: drawD.roomSortWay ?? 'letters',
            }
            this.drawOrderOrPanel(
              drawD.field ?? 'wardrobeNum',
              position,
              item.type
            )
            break
          case 'specialMark':
            drawD.typeName = item.type
            this.selectSpecialMark(drawD, 'init')
            //删除 typeName 元素

            delete drawD.typeName
            break
          case 'Table':
            const tableUrl = fabricTable(
              this[canvasId],
              true,
              false,
              drawD.fontSize * 2,
              'extraParts',
              10,
              10,
              drawD.fontFamily ?? '微软雅黑'
            )
            fabric.Image.fromURL(tableUrl, (img) => {
              img.set({
                top: drawD.top,
                left: drawD.left,
                fontPt: drawD.fontPt,
                fontSize: drawD.fontSize,
                hasControls: false,
                source_data: drawD.source_data,
                typeName: 'Table',
                mWidth: drawD.mWidth,
                mHeight: drawD.mHeight,
                // pxWidth: mmToPx(this.codeW, this.arrDPI),
                // pxHeight: mmToPx(this.codeH, this.arrDPI),
                showCode: true,
                scaleX: drawD.scaleX,
                scaleY: drawD.scaleY,
                fontFamily: drawD.fontFamily ?? '微软雅黑',
              })
              this.setItemId(img)
              this[canvasId].add(img)
            })
            break
          case 'Marking':
            // 绘制余料标识
            const circle = this.drawCircle(drawD.radius, drawD.mRadius, {
              top: drawD.top,
              left: drawD.left,
            })
            const surplusText = this.drawText(
              '余',
              drawD.top,
              drawD.left,
              drawD.fontSize,
              drawD.fontPt,
              drawD.fontWeight,
              {
                fontFamily: drawD.fontFamily ?? '微软雅黑',
              }
            )
            let group = new fabric.Group([circle, surplusText], {
              left: drawD.left,
              top: drawD.top,
              typeName: item.type,
              hasControls: false,
            })
            this.setItemId(group)
            this[this.mycanvasId].add(group)
            break
        }
      })
      // 绘制板件标注
      this.drawAllSignRect()
      // 初始化时默认选中第一个绘制的元素
      if (checkOne) {
        let klass = this[canvasId].getObjects()
        if (klass.length > 1) {
          this[canvasId].setActiveObject(klass[1])
          this.currentItem = klass[1]
          if (this.currentItem && this.currentItem.typeName === 'DataSource') {
            this.isAutoFontSize = this.currentItem.isAutoFontSize ?? true
          }
          this.dataSourceWidth = this.currentItem.rectWidth
            ? Number(this.currentItem.rectWidth).toFixed(2)
            : 100
          this.dataSourceHeight = this.currentItem.rectHeight
            ? Number(this.currentItem.rectHeight).toFixed(2)
            : 20
          if (klass[1].typeName === 'DataSource') {
            this.genControlRect(klass[1])
          }
          this.getKclassValueToAttr(klass[1])
        }
      }
    },
    upDateImg(object, codeType, kClass = null, type = '') {
      let urlInfo = this.createImgUrl(codeType, this.nowCodeInfo)
      let img = new Image()
      img.onload = () => {
        object?.item(0)?.setElement?.(img)
        // 好骚啊，不知道为啥要设置一个属性变化才能刷新才有效，只有group里刷新不了
        if (type != 'size') {
          this.codeW = pxTomm(urlInfo.width, this.arrDPI)
          this.codeH = pxTomm(urlInfo.height, this.arrDPI)
        }
        let scale = object.scaleX

        object.set({
          scaleX: scale == 1 ? 1.000001 : 1,
          width: mmToPx(this.codeW, this.arrDPI),
          height: mmToPx(this.codeH, this.arrDPI),
          mWidth: this.codeW,
          mHeight: this.codeH,
        })
        object.item(0).set({
          width: mmToPx(this.codeW, this.arrDPI),
          left: -mmToPx(this.codeW, this.arrDPI) / 2,
          top: -mmToPx(this.codeH, this.arrDPI) / 2,
          fontSize: PtToPx(this.nowFontSize, this.arrDPI),
          fontPt: this.nowFontSize,
        })
        clearTimeout(this.loadTime)
        this.loadTime = setTimeout(() => {
          if (urlInfo.width > mmToPx(this.codeW, this.arrDPI) + 0.5) {
            this.$message({
              type: 'warning',
              message: '条码长度超出边框，请调整边框宽高',
              duration: 1500,
            })
          }
          if (urlInfo.height > mmToPx(this.codeH, this.arrDPI) + 0.5) {
            this.$message({
              type: 'warning',
              message: '条码高度超出边框，请调整边框宽高',
              duration: 1500,
            })
          }
        }, 500)
        object.setCoords()
        this[this.mycanvasId].renderAll()
      }
      img.src = urlInfo.url
    },
    createImgUrl(codeType, source_data, inspect = true) {
      let num = 6160479583801
      if (source_data == 'plankNumF') {
        num = num + 'K'
      }
      let canvas = document.createElement('canvas')
      if (codeType == 'EAN13' && this.nowFontSize > 8) {
        this.nowFontSize = 8
      }
      JsBarcode(canvas, num, {
        format: codeType,
        width: 1.0,
        height: (mmToPx(10, this.arrDPI) / 3) * 2,
        margin: 0,
        fontOptions: this.fontWeight ? 'bold' : '',
        fontSize: PtToPx(this.nowFontSize, this.arrDPI),
        textMargin: this.lineSpace,
        font: this.nowFontFamily,
      })
      let url = canvas.toDataURL('image/png')
      return {
        url: url,
        width: canvas.width,
        height: canvas.height,
      }
    },
    createDefaultUrl(option) {
      let num = 6160479583801
      if (option.source_data == 'plankNumF') {
        num = num + 'K'
      }
      let canvas = document.createElement('canvas')
      JsBarcode(canvas, num, {
        format: option.codeType,
        width: 1.0,
        height: (mmToPx(10, this.arrDPI) / 3) * 2,
        margin: 0,
        fontOptions: option.fontWeight == 'bold' ? 'bold' : '',
        fontSize: PtToPx(option.fontPt, this.arrDPI),
        textMargin: option.lineHeight,
        font: option.fontFamily,
      })
      let url = canvas.toDataURL('image/png')
      return url
    },
    countOldTem() {
      this.mods = 0
      let saveValue = [
        'showCode',
        'item_id',
        'source_data',
        'mHeight',
        'mWidth',
        'code_type',
        'hasControls',
        'typeName',
        'fontPt',
        'fontSize',
        'fontWeight',
        'angle',
        'lineHeight',
        'scaleX',
        'scaleY',
        'textBackgroundColor',
        'selectable',
        'positionName',
        'oLeft',
        'oTop',
        'splitByGrapheme',
        'splitByGrapheme',
        'textAlign',
        'mRadius',
        '_isLine',
        '_isClone',
        '_afterOpacity',
      ]
      const json = this[this.mycanvasId].toJSON(saveValue)
      // 不存储线段元素和用于临时使用的克隆元素
      json.objects = json.objects.filter(
        (item) => !item._isLine && !item._isClone
      )
      // 重置部分操作带来的参数影响
      json.objects.forEach((item) => {
        // 透明度使用之前存储的
        item.opacity = item._afterOpacity ?? item.opacity
      })
      json.id = this.currentCanvas.lowerCanvasEl.id
      // 禁止上下拖动
      this.operateArrs.push(json) // toJSON-画板信息序列化成json
    },
    setControlVisible(tem, visible, ...values) {
      for (let i = 0; i <= values.length; i++) {
        tem['setControlVisible'](values[i], visible)
      }
    },
    listenerMousedown(e) {
      this.chooseOtherSet = false
      this.isDraw = false
    },
    listenerMousemove(e) {
      if (e.target.tagName != 'CANVAS' && this.isAllSelect) {
        this[this.mycanvasId].discardActiveObject() // 取消画布中所有对象的选中状态
        this[this.mycanvasId].requestRenderAll() // 请求重新渲染
        this.isAllSelect = false
      }
    },
    listenerMouseup(e) {
      this.chooseOtherSet = true
      if (this.nowETarget && this.isDraw && !this.mouseInCanvas) {
        this.nowETarget = null
        this.resetData(this.nowChooseSet)
        this.countOldTem()
        this.reDrawByCurrentPostion(this.overUpPoint, this.nowChooseSet)
      }
      this.isDraw = false
    },
    /** 重新绘制一个元素基于现在的位置 */
    reDrawByCurrentPostion(newPosition, type) {
      const { x: newX, y: newY } = newPosition
      switch (type) {
        case 'holeSlotPicture':
          fabric.Image.fromURL(require('../../assets/hole_slot.png'), (img) => {
            const unique = new Date().getTime().toString()
            img.set({
              top: newY,
              left: newX,
              hasControls: false,
              typeName: 'holeSlotPicture',
              holeF: this.holeF,
              holeZ: this.holeZ,
              mWidth: 19,
              mHeight: 19,
              uniqueID: unique,
              defaultScale: this.pictureScale,
            })
            img.scaleToWidth(mmToPx(this.codeW, this.arrDPI))
            img.scaleToHeight(mmToPx(this.codeH, this.arrDPI))
            const plankSize = [
              { value: '1220', name: 'width' },
              { value: '2440', name: 'height' },
            ]
            const w = mmToPx(this.codeW, this.arrDPI)
            const h = mmToPx(this.codeH, this.arrDPI)
            const textEls = []
            plankSize.forEach((item) => {
              let elW = this.getElementInfo('span', 'offsetWidth', item.value)
              elW = pxTomm(elW, this.arrDPI) / 2
              const text = new fabric.Text(item.value, {
                positionName: item.name,
                fontSize: this.nowFontSize,
                fontPt: this.nowFontSize,
                hasControls: false,
                createElementWidth: elW,
                isShow: this.plankSize,
                typeName: 'holeSlotPicture-size',
                uniqueID: unique,
                opacity: Number(this.plankSize),
                selectable: false,
                fontFamily: this.nowFontFamily,
              })
              const { top: imgTop, left: imgLeft } = img
              /**
               * moveF 附属元素跟随移动方法
               */
              let left = 0,
                top = 0
              // moveF = null
              switch (item.name) {
                case 'width':
                  left = imgLeft + w / 2
                  top = imgTop + h
                  break
                case 'height':
                  left = imgLeft + w
                  top = imgTop + h / 2
                  break
              }
              // 注入方法
              this.klassSetMethods(text, { show: 'plankSize' })
              text.set({
                top: top,
                left: left,
              })
              this.setItemId(text)
              this[this.mycanvasId].add(text)
              textEls.push(text)
            })
            this.setItemId(img)
            this[this.mycanvasId].add(img)
            // 添加附属元素
            img.set({
              auxiliaryEl: textEls,
            })
          })
          break
        case 'SurplusSize':
        case 'Typography':
          let imgUrl =
            type == 'Typography' ? 'paiban_test.png' : 'surplus_plank.png'
          fabric.Image.fromURL(require(`../../assets/${imgUrl}`), (img) => {
            img.set({
              left: 10,
              top: 10,
              hasControls: true,
              lockRotation: true,
              typeName: type,
              mWidth: type == 'Typography' ? 15 : 12,
              mHeight: type == 'Typography' ? 25 : 15,
            })
            this.imgScaleY(
              img,
              mmToPx(this.codeH, this.arrDPI) - 10,
              img.height
            )
            this.imgScaleX(img, mmToPx(this.codeW, this.arrDPI) - 10, img.width)
            this.setItemId(img)
            let edgeNumber = this.labelTemplates == 'plank' ? 0.5 : ''
            let edges =
              type == 'Typography'
                ? [
                    {
                      value: edgeNumber,
                      name: 'LE',
                    },
                    {
                      value: edgeNumber,
                      name: 'BE',
                    },
                    {
                      value: edgeNumber,
                      name: 'RE',
                    },
                    {
                      value: edgeNumber,
                      name: 'TE',
                    },
                  ]
                : [
                    {
                      value: 977,
                      name: 'RE',
                    },
                    {
                      value: 762,
                      name: 'BE',
                    },
                  ]
            let parentW = mmToPx(this.codeW, this.arrDPI)
            let parentH = mmToPx(this.codeH, this.arrDPI)
            let edgeText = []
            let openL = 0,
              openT = 0
            edges.forEach((item, index) => {
              let text = new fabric.Text(String(item.value), {
                positionName: item.name,
                fontSize: this.nowFontSize,
                fontPt: this.nowFontSize,
                fontFamily: this.nowFontFamily,
              })
              let left = 0
              let top = 0
              switch (item.name) {
                case 'LE':
                  top = parentH / 2
                  // left = -20;
                  break
                case 'BE':
                  top = parentH
                  left = parentW / 2
                  break
                case 'RE':
                  top = parentH / 2
                  left = parentW
                  openL = left
                  openT = top
                  break
                case 'TE':
                  // top = -20;
                  left = parentW / 2
                  break
              }
              text.set({
                top: top,
                left: left,
                oTop: top,
                oLeft: left,
              })
              edgeText.push(text)
            })
            const offsetLeft = edgeText[0].width + 6
            const offsetTop = edgeText[0].height / 2
            let circle
            if (type == 'Typography') {
              circle = this.drawCircle(6, 6, {
                left: openL + offsetLeft,
                top: openT + offsetTop,
                uniqueID: 'showDoor',
              })
            }
            const text = this.drawText(
              this.labelTemplates == 'plank' ? '开' : '',
              openT + offsetTop,
              openL + offsetLeft,
              8,
              12,
              '',
              {
                uniqueID: 'showDoor',
              }
            )
            let group = new fabric.Group(
              this.labelTemplates == 'plank'
                ? [img].concat(edgeText, circle, text)
                : [img].concat(edgeText, text),
              {
                top: newY,
                left: newX,
                fontPt: this.nowFontSize,
                typeName: type,
                width: parentW + offsetLeft,
                height: parentH,
                mWidth: this.codeW,
                mHeight: this.codeH,
                hasControls: false,
                showEdgeOff: this.showEdgeOff,
                showEdgeDisplay: this.showEdgeDisplay,
                showOpenDoor: this.showOpenDoor,
                plankRotate: this.plankRotate,
              }
            )
            // 处理缩放
            this.imgScaleY(group, mmToPx(this.codeH, this.arrDPI), group.height)
            this.imgScaleX(group, mmToPx(this.codeW, this.arrDPI), group.width)
            this.setItemId(group)
            this[this.mycanvasId].add(group)
          })
          break
        case 'FixedText':
          // eslint-disable-next-line no-case-declarations
          let fixedText = new fabric.Textbox('请双击输入文本', {
            top: newY,
            left: newX,
            fontSize: PtToPx(this.nowFontSize, this.arrDPI),
            fontPt: this.nowFontSize,
            typeName: 'FixedText',
            fontWeight: '',
            lineHeight: this.lineSpace,
            lockRotation: true,
            hasControls: true,
            // splitByGrapheme: this.splitByGrapheme,
            objectCaching: false,
            textAlign: 'left',
            fontFamily: this.nowFontFamily,
          })
          fixedText.set({
            originWidth: fixedText.width,
            originHeight: fixedText.height,
          })
          this.setControlVisible(fixedText, false, 'mtr')
          this.setItemId(fixedText)
          this[this.mycanvasId].add(fixedText)
          break
        case 'DataSource':
          // eslint-disable-next-line no-case-declarations
          let Text = new fabric.Text('请选择对应数据项', {
            top: newY,
            left: newX,
            fontSize: PtToPx(this.nowFontSize, this.arrDPI),
            fontPt: this.nowFontSize,
            typeName: 'DataSource',
            fontWeight: '',
            lineHeight: this.lineSpace,
            textBackgroundColor: '#F6F6F6',
            hasControls: true,
            source_data: '',
            textWrapping: 'wrap',
            isAutoFontSize: true,
            fontFamily: this.nowFontFamily,
          })
          Text.set({
            originWidth: Text.width,
            originHeight: Text.height,
            rectHeight: Text.height,
            rectWidth: Text.width,
          })
          this.setControlVisible(Text, false, 'mtr')
          this.setItemId(Text)
          this[this.mycanvasId].add(Text)
          this.isAutoFontSize = true
          break
        case 'QRcode':
          fabric.Image.fromURL(
            require('../../assets/tag_tem_test.png'),
            (img) => {
              img.set({
                // scaleX: mmToPx(this.codeH,this.arrDPI) / 200,
                // scaleY: mmToPx(this.codeH,this.arrDPI) / 200,
                top: newY,
                left: newX,
                hasControls: false,
                source_data:
                  this.labelTemplates == 'plank' ? 'ggid' : 'restockingCode',
                typeName: 'QRcode',
                mWidth: this.codeW,
                mHeight: this.codeH,
                pxWidth: mmToPx(this.codeW, this.arrDPI),
                pxHeight: mmToPx(this.codeH, this.arrDPI),
                showCode: true,
                isQRcodeNCSuffix: true,
              })
              this.imgScaleY(img, mmToPx(this.codeH, this.arrDPI), img.height)
              this.imgScaleX(img, mmToPx(this.codeW, this.arrDPI), img.width)
              this.setItemId(img)
              this[this.mycanvasId].add(img)
            }
          )
          break
        case 'Onecode':
          let codeImgInfo =
            this.labelTemplates == 'plank'
              ? this.createImgUrl('EAN13', 'plankNum')
              : this.createImgUrl('EAN13', 'oriPlankNum')
          let url = codeImgInfo.url
          fabric.Image.fromURL(url, (img) => {
            img.set({
              source_data:
                this.labelTemplates == 'plank' ? 'plankNum' : 'oriPlankNum',
              code_type: 'EAN13',
              fontPt: this.nowFontSize,
              fontSize: PtToPx(this.nowFontSize, this.arrDPI),
              lineHeight: this.lineSpace,
              width: codeImgInfo.width,
              fontFamily: this.nowFontFamily,
            })
            // img.set({
            //     left:0,
            //     top:0
            // })
            // this.imgScaleY(img,mmToPx(this.codeH - 3,this.arrDPI), img.height)
            // this.imgScaleX(img,mmToPx(this.codeW,this.arrDPI), img.width)
            // let oText = new fabric.Text('6160479583801',{
            //     top:25,
            //     fontPt:this.nowFontSize,
            //     fontSize:PtToPx(this.nowFontSize,this.arrDPI),
            //     lineHeight:this.lineSpace
            // })
            let W = pxTomm(codeImgInfo.width, this.arrDPI)
            let H = pxTomm(codeImgInfo.height, this.arrDPI)
            this.codeW = W
            this.codeH = H
            let group = new fabric.Group([img], {
              typeName: 'Onecode',
              hasControls: true,
              top: newY,
              left: newX,
              width: codeImgInfo.width,
              height: codeImgInfo.height,
              originWidth: codeImgInfo.width,
              originHeight: codeImgInfo.height,
              mWidth: W,
              mHeight: H,
              pxWidth: codeImgInfo.width,
              pxHeight: codeImgInfo.height,
              showCode: true,
              rotateDeg: 0,
              isOnecodeNCSuffix: true,

              // originY:'top',
              // originX: "left"
            })
            group.setControlVisible('mtr', false)
            this.setItemId(group)
            this[this.mycanvasId].add(group)
          })
          break
        case 'Graphics':
          let w = mmToPx(this.codeW, this.arrDPI)
          let h = mmToPx(this.codeH, this.arrDPI)
          let ox = newX
          let oy = newY
          let pointArr = [
            {
              x: ox,
              y: oy + h / 3, //1
            },
            {
              x: ox + w / 2, //2
              y: oy,
            },
            {
              x: ox + w,
              y: oy + h / 3, //3
            },
            {
              x: ox + (w / 3) * 2, //4
              y: oy + h / 3,
            },
            {
              x: ox + (w / 3) * 2, //5
              y: oy + h,
            },
            {
              x: ox + w / 3, //6
              y: oy + h,
            },
            {
              x: ox + w / 3, //7
              y: oy + h / 3,
            },
          ]
          let Graphics = new fabric.Polygon(pointArr, {
            top: oy,
            left: ox,
            angle: this.angle,
            fill: this.GraColor,
            hasControls: true,
            typeName: 'Graphics',
            originX: 'center',
            originY: 'center',
            mWidth: this.codeW,
            mHeight: this.codeH,
          })
          Graphics.setControlVisible('mtr', false)
          this.setItemId(Graphics)
          this[this.mycanvasId].add(Graphics)
          break
        // case 'Marking':
        case 'wardrobeNum':
          this.selectWardrobeNum(this.currentItem)
          break
        case 'specialMark':
          this.selectSpecialMark(this.currentItem, 'init')
          break
        case 'Table':
          const tableUrl = fabricTable(
            this[this.mycanvasId],
            true,
            false,
            PtToPx(this.nowFontSize, this.arrDPI) * 2,
            'extraParts',
            10,
            10,
            this.nowFontFamily
          )
          fabric.Image.fromURL(tableUrl, (img) => {
            img.set({
              top: newY,
              left: newX,
              fontPt: this.nowFontSize,
              fontSize: PtToPx(this.nowFontSize, this.arrDPI),
              hasControls: true,
              source_data: 'extraParts',
              typeName: 'Table',
              mWidth: img.width,
              mHeight: img.height,
              // pxWidth: mmToPx(this.codeW, this.arrDPI),
              // pxHeight: mmToPx(this.codeH, this.arrDPI),
              showCode: true,
              scaleX: 0.5,
              scaleY: 0.5,
              fontFamily: this.nowFontFamily,
            })
            img.setControlVisible('mtr', false)
            this.setItemId(img)
            // 查看setItemId
            this[this.mycanvasId].add(img)
          })
          break
        case 'Marking':
          // 绘制余料标识
          const circle = new fabric.Circle({
            radius: mmToPx(this.nowDiameter / 2, this.arrDPI),
            fill: '#fff',
            stroke: '#000',
            strokeWidth: 1,
            mRadius: this.nowDiameter,
            originX: 'center',
            originY: 'center',
            top: this.nowDiameter,
            left: this.nowDiameter,
          })
          const text = new fabric.IText('余', {
            fontSize: PtToPx(this.nowFontSize, this.arrDPI) ?? 13,
            fontPt: this.nowFontSize ?? 10,
            fontWeight: this.fontWeight ? 'bold' : '',
            originX: 'center',
            originY: 'center',
            top: this.nowDiameter,
            left: this.nowDiameter,
            fontFamily: this.nowFontFamily ?? '微软雅黑',
          })
          const group = new fabric.Group([circle, text], {
            top: newY,
            left: newX,
            hasControls: false,
            typeName: 'Marking',
          })
          this.setItemId(group)
          this[this.mycanvasId].add(group)
          break
      }
      this.currentCanvas.renderAll()
    },
    // 在fabric画布上按下
    listenerContextMenu(e) {
      if (e.button === 2) {
        this.isFabricCanvasMove = true
        this.nowChooseSet = null
        this.fabricCutPoint = { x: e.offsetX, y: e.offsetY }
        e.preventDefault()
      }
    },
    // 抬起
    listenerFabricMouseUp() {
      this.isFabricCanvasMove = false
    },
    // 改变画布位置
    listenerFabricMouseMove(e) {
      if (this.isFabricCanvasMove) {
        const { offsetX, offsetY } = e
        const fabricCanvasOffset = {
          x: offsetX - this.fabricCutPoint.x,
          y: offsetY - this.fabricCutPoint.y,
        }
        this.setFabricTransform(fabricCanvasOffset.x, fabricCanvasOffset.y)
        this.fabricCutPoint = { x: offsetX, y: offsetY }
      }
    },
    // 绘制圆形
    drawCircle(radius, mRadius, other = {}) {
      return new fabric.Circle({
        radius: radius, //圆形半径
        fill: '#fff', //填充的颜色
        stroke: '#000', // 边框颜色
        strokeWidth: 1, // 边框大小
        mRadius,
        originX: 'cneter',
        originY: 'cneter',
        left: radius,
        top: radius,
        ...other,
      })
    },
    drawText(text, top, left, pt, fontSize, fontWeight, other = {}) {
      return new fabric.IText(text + '', {
        fontSize: pt,
        fontPt: fontSize,
        fontWeight: fontWeight,
        originX: 'cneter',
        originY: 'cneter',
        left: left,
        top: top,
        hasControls: true,
        ...other,
      })
    },
    async listenerKeydown(e) {
      let that = this

      // 需要移动的元素
      let canvasItems = [...this.controlRects, this.currentItem]

      let payload = null

      // 方向键按下移动事件
      switch (e.key) {
        case 'ArrowLeft':
          payload = ['left', 'sub']
          break
        case 'ArrowUp':
          payload = ['top', 'sub']
          break
        case 'ArrowRight':
          payload = ['left', 'add']
          break
        case 'ArrowDown':
          payload = ['top', 'add']
          break
        default:
          break
      }
      if (payload) {
        this.handleKeybordMove(canvasItems, ...payload)
      }
      const page = this[this.mycanvasId]
        .getObjects()
        .find((item) => item.typeName == 'TageBox')
      // page && this[this.mycanvasId].sendToBack(page)
      this[this.mycanvasId].renderAll()

      if (e.keyCode == 46) {
        that.deleteEl()
      }
      if (e.keyCode == 17) {
        that.mouse_wheel = true
        that.keyboard.isCtrlDown = true
        that.getCurrentUseSelectedControl()?.current.currentSelected?.set({
          evented: false,
          selectable: false,
        })
        /** 改变内部按键状态 */
        fabricMovingControlList.forEach((item) => {
          item.keyboardStatusChange('ctrl', true)
        })
      }
      if (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) {
        e.preventDefault()
        if (e.keyCode == 83) {
          that.save()
        } else if (e.keyCode == 67 && that.currentItem) {
          // 复制
          that.copyValue = handleTagSaveData([that.currentItem])
        } else if (e.keyCode == 86 && that.copyValue.length) {
          // 粘贴
          that.copyValue[0].data.left += 10
          that.copyValue[0].data.top += 10
          that.copyValue[0].item_id = nanoid()
          that.countOldTem()
          that.drawItemTem(that.copyValue, false, this.mycanvasId)
        } else if (e.keyCode == 90) {
          // 撤销
          const operateArrs = that.operateArrs.filter(
            (it) => it.id === that.currentCanvas.lowerCanvasEl.id
          )
          if (operateArrs.length) {
            const index = operateArrs.length - 1
            that.currentItem = null
            that[this.mycanvasId].clear().renderAll()
            that[this.mycanvasId].loadFromJSON(operateArrs[index])

            let items = that[this.mycanvasId].getObjects()
            for (let i = 0; i < items.length; i++) {
              if (items[i].typeName == 'FixedText') {
                this.setControlVisible(items[i], false, 'mtr', 'mt', 'mb')
              }
            }
            that[this.mycanvasId].renderAll()
            // 需要清空之前的板件标注信息
            this.plankSignBox = []
            // 此处是因为直接获取getObjects是空的，可能是内部有异步，所以在此处等待异步完成
            await new Promise((resolve) => setTimeout(resolve, 1))
            this.plankSignBox = this[this.mycanvasId]
              .getObjects()
              .filter((klass) => klass.source_data == 'plankSign')
            this.drawAllSignRect()
            // 再找到恢复的数据并删除此记录
            const targetIdx = that.operateArrs.findIndex(
              (it) => it === operateArrs[index]
            )
            that.operateArrs.splice(targetIdx, 1)
          }
        }
      }
    },

    handleKeybordMove(data, key, type) {
      data.forEach((obj) => {
        const payload = {}
        payload[key] = type == 'add' ? obj?.[key] + 1 : obj?.[key] - 1
        Object.keys(payload).forEach((key) => {
          obj?.set(key, payload[key])
        })
      })
    },

    listenerMousewheel(e) {
      if (e.ctrlKey) {
        e.preventDefault()
      }
    },
    getKclassValueToAttr(obj) {
      if (!obj) return
      this.nowChooseSet = obj.typeName
      this.fontSizeOpeion = [4, 6, 8, 10, 12, 14, 16, 20, 26, 32]
      this.inputAlert.fontSizeAlert = ''
      switch (obj.typeName) {
        case 'FixedText':
          this.nowFontSize = obj.fontPt
          this.fontWeight = obj.fontWeight == 'bold' ? true : false
          this.lineSpace = obj.lineHeight
          this.splitByGrapheme = obj.splitByGrapheme
          this.textAlign = obj.textAlign ? obj.textAlign : 'left'
          this.nowFontFamily = obj.fontFamily ?? '微软雅黑'
          break
        case 'Typography': {
          this.codeW = obj.mWidth
          this.codeH = obj.mHeight
          let TypographyTextItems = obj.getObjects()[1]
          this.nowFontSize = TypographyTextItems.fontPt
          this.fontWeight =
            TypographyTextItems.fontWeight == 'bold' ? true : false
          this.showEdgeOff = obj.showEdgeOff
          this.showEdgeDisplay = obj.showEdgeDisplay
          this.showOpenDoor = obj.showOpenDoor
          this.plankRotate = obj.plankRotate
          this.nowFontFamily = TypographyTextItems.fontFamily
          break
        }
        case 'Onecode':
          // let oneCodeText = obj.getObjects()[1];
          let oneCodeImg = obj.getObjects()[0]
          this.nowFontSize = oneCodeImg.fontPt
          this.fontWeight = oneCodeImg.fontWeight == 'bold' ? true : false
          this.lineSpace = oneCodeImg.lineHeight
          this.codeW = obj.mWidth
          this.codeH = obj.mHeight
          this.nowCodeInfo = oneCodeImg.source_data ?? true
          this.codeAtt = oneCodeImg.code_type
          this.switchCodeValue = obj.showCode
          this.isOnecodeNCSuffix = obj.isOnecodeNCSuffix
          this.nowFontFamily = oneCodeImg.fontFamily
          if (
            ['plankNum', 'oriPlankNum', 'remarks', 'ncName'].includes(
              oneCodeImg.source_data
            )
          ) {
            this.codeAttOpeion = mockData.codeAttOpeion
          } else {
            this.codeAttOpeion = mockData.codeAttOpeionK
          }
          if (oneCodeImg.code_type == 'EAN13') {
            this.fontSizeOpeion = [4, 6, 8]
          } else {
            this.fontSizeOpeion = [4, 6, 8, 10, 12, 14, 16, 20, 26, 32]
          }
          break
        case 'QRcode':
          this.codeW = obj.mWidth
          this.codeH = obj.mHeight
          this.nowCodeInfo = obj.source_data
          this.switchCodeValue = obj.showCode
          this.isQRcodeNCSuffix = obj.isQRcodeNCSuffix ?? true
          this.QRCodeFormat = obj.QRCodeFormat ?? 'QRCode'
          break
        case 'DataSource':
          this.nowFontSize = obj.fontPt
          this.fontWeight = obj.fontWeight == 'bold' ? true : false
          this.lineSpace = obj.lineHeight
          this.nowDataInfo = obj.source_data
          if (obj.source_data == 'oSize' || obj.source_data == 'cutSize') {
            this.sizeRestrict = obj.sizeRestrict ? obj.sizeRestrict : false
            // moduleq1 因为小数位数设置切割/成品用的同一个组件，但此处会更改绑定值，触发到 change 事件导致数据 BUG，所以这里手动触发一次失焦
            this.$refs['sizeRestrictNumRef']?.blur()
            this.sizeRestrictNum = obj.sizeRestrictNum ?? 2
          }
          if (obj.source_data == 'sameSizeBoardCount') {
            this.statisticsDimension = obj.statisticsDimension ?? 'noLimit'
          }
          this.nowFontFamily = obj.fontFamily ?? '微软雅黑'
          break
        case 'Graphics':
          this.codeH = obj.mHeight
          this.codeW = obj.mWidth
          this.GraColor = obj.fill
          // 和 moduleq1 相同的问题
          this.$refs['angleRef']?.blur()
          this.angle = obj.angle
          break
        case 'SurplusSize': {
          this.codeW = obj.mWidth
          this.codeH = obj.mHeight
          let SurplusSizeTextItems = obj.getObjects()[1]
          this.nowFontSize = SurplusSizeTextItems.fontPt
          this.fontWeight =
            SurplusSizeTextItems.fontWeight == 'bold' ? true : false
          this.nowFontFamily = SurplusSizeTextItems.fontFamily
          break
        }
        case 'Marking':
        case 'wardrobeNum':
          if (typeof obj.getObjects !== 'function') {
            break
          }
          let cir = obj.getObjects()[0]
          let cirNum = obj.getObjects()[1]
          this.nowDiameter = cir.mRadius
          this.fontWeight = cirNum.fontWeight == 'bold' ? true : false
          this.nowFontSize = cirNum.fontPt
          if (obj.typeName == 'wardrobeNum') {
            this.partSignValue = obj.field ?? 'wardrobeNum'
            if (this.partSignValue == 'wardrobeNumToRoom') {
              this.roomSortWay = cirNum.roomSortWay
            }
          }
          this.nowFontFamily = cirNum.fontFamily
          break
        case 'holeSlotPicture':
          const auxiliaryItem = obj.auxiliaryEl?.[0]
          if (!auxiliaryItem) {
            break
          }
          this.fontWeight = auxiliaryItem.fontWeight == 'bold' ? true : false
          this.nowFontSize = auxiliaryItem.fontPt
          this.plankSize = auxiliaryItem.isShow
          this.holeF = obj.holeF
          this.holeZ = obj.holeZ
          this.pictureScale = obj.defaultScale
          this.nowFontFamily = auxiliaryItem.fontFamily
          break
        case 'Table':
          this.nowTableData = obj.source_data
          this.nowFontSize = obj.fontPt
          this.nowFontFamily = obj.fontFamily ?? '微软雅黑'
          break
        default:
          break
      }
    },
    // 获取元素的一些属性
    getElementInfo(elName, attribute, msg, style = {}) {
      const el = document.createElement(elName)
      el.innerHTML = msg
      document.body.appendChild(el)
      const styles = Object.keys(style)
      if (styles.length) {
        styles.forEach((k) => {
          el.style[k] = style[k]
        })
      }
      const d = el[attribute]
      document.body.removeChild(el)
      return d
    },
    // 注入给子级元素的一些方法
    klassSetMethods(klass, attrs) {
      const showF = function (that) {
        this.set({
          isShow: that[attrs.show],
          opacity: Number(that[attrs.show]),
        })
      }
      const fontF = function (font) {
        this.set({ ...font })
      }
      if (klass.positionName) {
        let moveF = null
        switch (klass.positionName) {
          case 'width':
            moveF = function (oriKlass) {
              const w = mmToPx(oriKlass.mWidth, DPI)
              const h = mmToPx(oriKlass.mHeight, DPI)
              this.set({
                left: oriKlass.left + w / 2,
                top: oriKlass.top + h,
              })
            }
            break
          case 'height':
            moveF = function (oriKlass) {
              const w = mmToPx(oriKlass.mWidth, DPI)
              const h = mmToPx(oriKlass.mHeight, DPI)
              this.set({
                left: oriKlass.left + w,
                top: oriKlass.top + h / 2,
              })
            }
            break
        }
        klass.set({
          moveF: moveF.bind(klass),
        })
      }
      klass.set({
        showF: showF.bind(klass, this),
        fontF: fontF.bind(klass),
      })
    },
    // 绘制板件特殊标识
    drawOrderOrPanel(field, position, from) {
      const {
        radius: pRadius,
        fontSize,
        left,
        top,
        fontWeight,
        fontFamily,
        roomSortWay,
      } = position
      const radius = mmToPx(pRadius / 2, this.arrDPI)
      const circle = new fabric.Circle({
        radius: radius, //圆形半径
        fill: '#fff', //填充的颜色
        stroke: '#000', // 边框颜色
        strokeWidth: 1, // 边框大小
        mRadius: pRadius,
        originX: 'cneter',
        originY: 'cneter',
        left: radius,
        top: radius,
      })
      let t = ''
      if (from === 'wardrobeNum') {
        // 由于选择了按房间排序后，需要对其余两个字段进行初始化赋值
        if (field === 'wardrobeNum') {
          t = '1'
        } else if (field === 'isFrontPlank') {
          t = '面'
        } else if (field === 'wardrobeNumToRoom') {
          this.roomSortWay = roomSortWay
          if (this.roomSortWay === 'letters') t = 'A-1'
          else t = '1-1'
        }
      } else {
        t = '余'
      }

      const numText = new fabric.IText(t, {
        fontSize: PtToPx(fontSize, this.arrDPI),
        fontPt: fontSize,
        fontWeight: fontWeight ?? '',
        originX: 'cneter',
        originY: 'cneter',
        left: radius,
        top: radius,
        fontFamily: fontFamily,
        roomSortWay: roomSortWay,
      })
      const cirGroup = new fabric.Group([circle, numText], {
        left: left,
        top: top,
        typeName: from,
        hasControls: false,
        field,
      })
      this.setItemId(cirGroup)
      this.currentItem = cirGroup
      this[this.mycanvasId].add(cirGroup)
      this.getKclassValueToAttr(cirGroup)
    },
    // 添加画布的监听
    addFabricCanvasListener() {
      const fabricCreateEl = document.querySelector('.upper-canvas')
      // 监听鼠标右键
      fabricCreateEl.addEventListener('mousedown', this.listenerContextMenu)
      fabricCreateEl.addEventListener('mouseup', this.listenerFabricMouseUp)
      fabricCreateEl.addEventListener('mousemove', this.listenerFabricMouseMove)
      // 取消默认菜单显示
      fabricCreateEl.addEventListener('contextmenu', (e) => e.preventDefault())
    },
    // 移动fabric画布到中心点
    moveFabricCanvasToCenter() {
      const centerPoint = this[this.mycanvasId].getCenter() // 获取中心坐标
      const { left, top, height, width } = this[this.mycanvasId].getObjects()[0] // getObjects 获取画布上的所有对象
      const cutLeft = centerPoint.left - (left + width / 2)
      const cutTop = centerPoint.top - (top + height / 2)
      this.setFabricTransform(cutLeft, cutTop)
    },
    // 更改fabric画布位置
    setFabricTransform(x, y) {
      const transform = this[this.mycanvasId].viewportTransform.slice()
      transform[4] += x // 水平移动
      transform[5] += y // 垂直移动
      this[this.mycanvasId].setViewportTransform(transform) // 设置画布视口转换
      this[this.mycanvasId].renderAll() // 重新渲染一遍画布，当画布中的对象有变更，在最后显示的时候，需要执行一次该操作
    },

    // 在元素位置生成操作框
    genControlRect(obj) {
      /** 当 ctrl 键位按下时点击元素不需要生成操作框，避免影响其他功能的运行 */
      if (this.keyboard.isCtrlDown) {
        return
      }
      const { width, height, left, top, rectWidth, rectHeight, source_data } =
        obj
      // obj为激活元素
      const ids = this.hasGenControlRectList.map((item) => item.item_id)
      if (!ids.includes(obj.item_id)) {
        const ControlRect = new fabric.Rect({
          width: rectWidth ?? width + 2,
          height: rectHeight ?? height + 2,
          left: left - 1,
          top: top - 1,
          fill: 'transparent',
          stroke: '#b8d0ff',
          source_data,
          typeName: 'controlRect',
          item_id: obj.item_id,
          strokeWidth: 0.5,
        })
        ControlRect.setControlVisible('mtr', false)
        this.hasGenControlRectList.push(obj)
        this.controlRects.push(ControlRect)
        this[this.mycanvasId].add(ControlRect)
        this[this.mycanvasId].setActiveObject(ControlRect)
      }
    },

    // 清除所有的控制框
    clearControlRect() {
      this.hasGenControlRectList = []
      this.currentCanvas.getObjects().forEach((item) => {
        if (isControlRectEl(item)) {
          this.currentCanvas.remove(item)
        }
      })
      this[this.mycanvasId].renderAll()
      this.controlRects = []
    },
    handleChangeDataSourceSize(e) {
      if (e.key === 'Enter') {
        this.currentItem.rectHeight = Number(this.dataSourceHeight)
        this.currentItem.rectWidth = Number(this.dataSourceWidth)
        this.controlRects[0].set({
          width: Number(this.dataSourceWidth),
          height: Number(this.dataSourceHeight),
        })
        this[this.mycanvasId].renderAll()
      }
    },
    handleFocusRectSize() {
      window.removeEventListener('keydown', this.listenerKeydown, false)
    },
    handleBlurRectSize() {
      window.addEventListener('keydown', this.listenerKeydown)
    },
    handleChangeAutoFontSize() {
      const obj = this.currentItem
      if (!obj.text) return
      const { text, fontSize } = dealTextStr(
        obj.text,
        obj.fontSize,
        obj.rectWidth,
        obj.rectHeight,
        this.isAutoFontSize
      )
      obj.text = text
      if (this.isAutoFontSize) {
        obj.fontSize = fontSize
        obj.fontPt = Math.ceil(PxToPt(fontSize))
        this.nowFontSize = Math.ceil(PxToPt(fontSize))
      }
      obj.set({
        isAutoFontSize: this.isAutoFontSize,
      })
      this[this.mycanvasId].renderAll()
    },
    // 统计维度
    handleChangeStatisticsDimension() {
      this.countOldTem() // 保存顺序，序列化，不知道有啥用，先写着
      let current = this.currentItem
      if (current) {
        if (current.source_data == 'sameSizeBoardCount') {
          current.set({
            statisticsDimension: this.statisticsDimension,
          })
        }
      }
    },
    // 根据字体或其他变化改变当前元素内rectWidth/Height值的大小以及控制框的大小
    changeKlassControlRect(klass) {
      if (!klass) return
      let { width, height } = klass
      if (isNaN(width)) return
      width = parseInt(width)
      height = parseInt(height)
      klass.rectWidth = width
      klass.rectHeight = height
      this.controlRects[0]?.set({ width, height })
      this.dataSourceWidth = width
      this.dataSourceHeight = height
    },
    handleChangeQRNCSuffix() {
      this.currentItem.set({
        isQRcodeNCSuffix: this.isQRcodeNCSuffix,
      })
      this[this.mycanvasId].renderAll()
    },
    handleChangeOneNCSuffix() {
      this.currentItem.set({
        isOnecodeNCSuffix: this.isOnecodeNCSuffix,
      })
      this[this.mycanvasId].renderAll()
    },
    handleChangeQRCodeFormat() {
      this.currentItem.set({
        QRCodeFormat: this.QRCodeFormat,
      })
    },
    // 修改按房间排序方式
    handleChangeRoomSort(value) {
      const current = this.currentItem
      current.set({
        field: `wardrobeNumToRoom`,
      })
      const textObj = current.getObjects()[1]
      const currentText = textObj.text
      const wardrobeSort = currentText.split('-')[1]
      // 按字母排序
      if (value === 'letters') {
        textObj.set({
          text: `A-${wardrobeSort}`,
          roomSortWay: value,
        })
      } else {
        textObj.set({
          text: `1-${wardrobeSort}`,
          roomSortWay: value,
        })
      }
      this[this.mycanvasId].renderAll()
    },
    translateLang(key) {
      return translate(key)
    },
    // 数据信息实现模糊搜索
    filterOption(value, option) {
      return option.componentOptions.children[0].text?.includes(value)
    },
    /** 处理其他操作点击 */
    handleOtherClick(operate) {
      const actives = getActiveObjectByNotAssignField(this.currentCanvas)
      // 获取选中元素的边界点
      const points = actives.map((it) => {
        const buound = getElRealRect(it)
        return [
          { x: buound.x || 0, y: buound.y || 0 },
          {
            x: buound.x + buound.width || 0,
            y: buound.y + buound.height || 0,
          },
        ]
      })
      /** 均分公共逻辑 */
      const distributeElements = (
        sortedObjects,
        fixedStart,
        totalSpace,
        getSize,
        posKey
      ) => {
        const totalObjectSize = sortedObjects
          .slice(1, -1) // 中间元素
          .reduce((sum, obj) => sum + getSize(obj), 0)
        const gap = (totalSpace - totalObjectSize) / (sortedObjects.length - 1)
        let currentPos = fixedStart + getSize(sortedObjects[0])
        sortedObjects.slice(1, -1).forEach((obj) => {
          const position = {}
          const { x, y } = getElRealRect(obj)
          const startX = currentPos + gap
          const oriPos = posKey === 'left' ? x : y
          const diffVal = oriPos - startX
          position[posKey] = obj[posKey] - diffVal
          obj.set(position)
          currentPos += getSize(obj) + gap
        })
      }
      const [minx, miny, maxx, maxy] = getPathMinMaxPoint(points.flat(1))
      const center = { x: (maxx + minx) / 2, y: (miny + maxy) / 2 }
      /**
       * 下面的计算为什么会不直接将值等于某一个计算出来的边界，比如左对齐直接=最小 x？
       * 因为 fabric 原点不再中心，然后基于中心旋转之后的 left top 很奇怪，left top 也顺时针旋转了，比如旋转
       * 90° 那么旋转后的左下角点位将替代左上角的点位，所以这里都是基于差值来得到要移动的位置，单是origin 是center 则又不需要处理
       */
      switch (operate.type) {
        case 'leftAlign':
          actives.forEach((it) => {
            const { x } = getElRealRect(it)
            const diffVal = it.left - (x - minx)
            it.set({ left: diffVal })
          })
          break
        case 'rightAlign':
          actives.forEach((it) => {
            const { x, width } = getElRealRect(it)
            const diffVal = it.left - (x + width - maxx)
            it.set({ left: diffVal })
          })
          break
        case 'topAlign':
          actives.forEach((it) => {
            const { y } = getElRealRect(it)
            const diffVal = it.top - (y - miny)
            it.set({ top: diffVal })
          })
          break
        case 'bottomAlign':
          actives.forEach((it) => {
            const { y, height } = getElRealRect(it)
            const diffVal = it.top - (y + height - maxy)
            it.set({ top: diffVal })
          })
          break
        case 'horizontalAlign':
          actives.forEach((it) => {
            const { x, width } = getElRealRect(it)
            const diffVal = it.left - (x + width / 2 - center.x)
            it.set({ left: diffVal })
          })
          break
        case 'verticalAlign':
          actives.forEach((it) => {
            const { y, height } = getElRealRect(it)
            const diffVal = it.top - (y + height / 2 - center.y)
            it.set({ top: diffVal })
          })
          break
        case 'horizontalSplit':
          {
            const sortedObjects = [...actives].sort((a, b) => {
              a = getElRealRect(a)
              b = getElRealRect(b)
              return (a.x || 0) - (b.x || 0)
            })
            const first = getElRealRect(sortedObjects[0])
            const last = getElRealRect(sortedObjects[sortedObjects.length - 1])
            distributeElements(
              sortedObjects,
              first.x,
              last.x - first.x - first.width,
              (obj) => getElRealRect(obj).width,
              'left'
            )
          }
          break
        case 'verticalSplit':
          {
            const sortedObjects = [...actives].sort((a, b) => {
              a = getElRealRect(a)
              b = getElRealRect(b)
              return (a.y || 0) - (b.y || 0)
            })
            const first = getElRealRect(sortedObjects[0])
            const last = getElRealRect(sortedObjects[sortedObjects.length - 1])
            distributeElements(
              sortedObjects,
              first.y,
              last.y - first.y - first.height,
              (obj) => getElRealRect(obj).height,
              'top'
            )
          }
          break
      }
      // 孔槽图需要同步一下移动
      actives.forEach((object) => {
        object.auxiliaryEl?.forEach((klass) => {
          klass.moveF(object)
        })
      })
      // 更新画布
      this.currentCanvas.discardActiveObject()
      this.calcActiveCount(this.currentCanvas)
      this.currentCanvas.renderAll()
    },
    /** 在存储的控制类中找到指定的类 */
    getCurrentUseSelectedControl(canvas) {
      return fabricControlList.find(
        (item) => item.canvas === (canvas || this.currentCanvas)
      )
    },
    /** @desc 创建自定义的 fabric 控制类 */
    createFabricControl(canvas) {
      //  创建 FabricSelectedControl 对象 用于控制 fabric 多选等相关操作
      const fabricControl = new FabricSelectedControl(canvas)
      fabricControl.on('selectedUpdate', (e) => {
        const first = e?.getObjects()?.[0]
        console.log(first)

        this.nowChooseSet = e.typeName
        this.currentItem = e
        // 记录激活个数
        this.calcActiveCount(canvas)
        // 重置部分数据
        this.codeH = 10
        this.codeW = 10
        this.angle = 0
        this.fontWeight = false
        // this.nowFontSize = ''
        // this.nowFontFamily = '微软雅黑'
        this.showEdgeOff = false
        this.showEdgeDisplay = false
        this.showOpenDoor = false
        this.plankRotate = 0
        this.textAlign = 'left'
        this.QRCodeFormat = 'QRCode'
        this.isQRcodeNCSuffix = true
        this.nowCodeInfo = ''
        this.nowDataInfo = ''
        this.lineSpace = 1
        // this.nowTableData = ''
        this.plankSize = false
        this.holeF = false
        this.holeZ = false
        this.pictureScale = 1
      })
      // 监听选区值的设置，便于单个修改
      fabricControl.on('selectedAttributeUpdate', (objects, options) => {
        Object.keys(options).forEach((k) => {
          switch (k) {
            case 'fontSize':
              objects.forEach((object) =>
                this.handleFontSizeChange(options['fontPt'], object)
              )
              break
            case 'fontWeight':
              objects.forEach((object) => this.changeFontWeight(object))
              break
            case 'fontFamily':
              objects.forEach((object) =>
                this.handleFontFamilyChange(options[k], object)
              )
              break
            default:
              objects.forEach((object) => object.set({ ...options }))
          }
        })
      })
      // 记录创建的控制类
      fabricControlList.push(fabricControl)
      // 移动控制,对目标的移动进行控制
      const fabricMoveingControl = new FabricMovingControl(canvas)
      fabricMovingControlList.push(fabricMoveingControl)
    },
    /** @description 计算活跃元素个数 用于显示对齐功能，但默认限制了部分活跃不计算 */
    calcActiveCount(canvas, cb) {
      cb =
        cb ||
        function (it) {
          return (
            !['holeSlotPicture-size'].includes(it.typeName) && !isSelectedEl(it)
          )
        }
      const count =
        canvas?.getActiveObjects().filter((it) => cb(it))?.length || 0
      this.activeElementCount = count
    },
  },
  async created() {
    // 从接口获取小板的数据信息
    const { data: res } = await getLabelInfo()
    if (res.status) {
      this.dataSources.fieldData = res.data.map((item) => {
        item.text = item.sample
        return item
      })
    }
  },
  // TODO 检查页面上出现两次图片的原因
  async mounted() {
    // 非老板良定制用户没有包数选项
    if (!needApartPackUser.includes(this.$store.state.userInfo.phone)) {
      if (this.dataSources.fieldData && this.dataSources.fieldData.length) {
        const packageCountIdx = this.dataSources.fieldData.findIndex(
          (it) => it.value === 'packageCount'
        )
        // 删除包数选项
        if (packageCountIdx !== -1) {
          this.dataSources.fieldData.splice(packageCountIdx, 1)
        }
      }
    }
    if (sessionStorage.getItem('thinkerx_material')) {
      this.thinkerxMaterial = true
    }

    this.arrDPI = getDeviceXDPI()
    let tagId = this.$route.query.id
    let source = null
    let surplusSource = null
    if (tagId) {
      await this.$getByToken('/load_tag_setting', { id: tagId }, (res) => {
        if (res.status == 1) {
          let data = res.data.data
          this.rectData = data.rectInfo
          // 小板标签模板
          data.tem_data.forEach((item) => {
            if (item.data && item.data.source_data == 'oSize') {
              item.data = { ...item.data, text: '2440X1220X18' }
            }
            if (item.data && item.data.source_data == 'plankSize') {
              item.data = { ...item.data, text: '2440X1220X18' }
            }
          })
          // 余料标签模板
          if (!data.surplus_data)
            data.surplus_data = JSON.parse(defaultTagData).surplus_tem_data
          data.surplus_data.forEach((item) => {
            if (item.data && item.data.source_data == 'oSize') {
              item.data = { ...item.data, text: '2440X1220X18' }
            }
            if (item.data && item.data.source_data == 'plankSize') {
              item.data = { ...item.data, text: '2440X1220X18' }
            }
          })
          source = data.tem_data
          surplusSource = data.surplus_data
          this.tagName = res.data.name
          this.tagSizeWmm = data.tag_width
          this.tagSizeHmm = data.tag_height
          this.saveCanvas = data
          this.saveCanvas.name = res.data.name
        } else {
          source = null
          surplusSource = null
        }
      })
      this.tagId = tagId ? tagId : 0
    } else {
      let data = JSON.parse(defaultTagData)
      this.rectData = data.rectInfo
      // 获取小板标签模板的默认数据
      source = data.tem_data
      // 获取余料标签模板的默认数据
      surplusSource = data.surplus_tem_data
      this.tagName = '自定义标签'
      this.tagSizeWmm = data.tag_width
      this.tagSizeHmm = data.tag_height
      this.saveCanvas = data
      this.saveCanvas.name = '自定义标签'
    }

    window.addEventListener('mousedown', this.listenerMousedown)
    window.addEventListener('mousemove', this.listenerMousemove)
    window.addEventListener('mouseup', this.listenerMouseup)
    // 禁止滚轮默认事件
    window.addEventListener('mousewheel', this.listenerMousewheel, {
      passive: false,
    })
    // 进入的时求返候就根据请回的数据源进行绘制
    // 需要绘制小板标签模板和余料标签模板两种数据 需要先绘制余料标签，保证此时的this.mycanvasId的值
    // 余料标签模板
    // this.draw(surplusSource, false, 'mycanvas_surplus')
    this.draw(surplusSource, false, 'mycanvas_surplus')
    // 小板标签模板
    this.draw(source, false, 'mycanvas_tag')
    let that = this
    window.addEventListener('keydown', this.listenerKeydown)
    window.addEventListener('keyup', (e) => {
      if (e.keyCode == 17) {
        this.mouse_wheel = false
        this.keyboard.isCtrlDown = false
        this.getCurrentUseSelectedControl()?.current.currentSelected?.set({
          evented: true,
          selectable: true,
        })
        /** 改变内部按键状态 */
        fabricMovingControlList.forEach((item) => {
          item.keyboardStatusChange('ctrl', false)
        })
      }
    })
    // 循环
    for (let i = 0; i < canvasIdList.length; i++) {
      let mycanvas = this[canvasIdList[i]]
      this.createFabricControl(mycanvas)
      let isMove = false
      let point = {
        x: mycanvas.width / 2,
        y: mycanvas.height / 2,
      }
      this.canvasZoomPoint = point
      mycanvas.zoomToPoint(point, this.zoomNum)
      mycanvas.on({
        'mouse:wheel': (e) => {
          if (that.mouse_wheel) {
            var zoom = (event.deltaY > 0 ? -0.1 : 0.1) + mycanvas.getZoom()
            zoom = Math.max(0.1, zoom) //最小为原来的1/10
            zoom = Math.min(3, zoom) //最大是原来的3倍
            var zoomPoint = new fabric.Point(e.pointer.x, e.pointer.y)
            that.zoomNum = Number(zoom.toFixed(2))
            mycanvas.zoomToPoint(zoomPoint, zoom)
          }
        },
        'mouse:move': (e) => {
          if (e.target) {
            this.isDraw = true // 是否可以绘制，只有点击标签数据栏拖动才能绘制
            this.nowETarget = e.target
            this.overUpPoint = e.absolutePointer
            if (isMove) {
              this.countOldTem()
              isMove = false
            }
            if (
              this.nowETarget.source_data == 'plankSignBox' &&
              this.plankSignBox.length
            ) {
              let sign = this.plankSignBox.find(
                (item) => item.item_id == this.nowETarget.item_id
              )
              sign.left = this.nowETarget.left + pxTomm(30, this.arrDPI) / 2
              sign.top = this.nowETarget.top + pxTomm(30, this.arrDPI) / 2
              this[canvasIdList[i]].renderAll()
            }
            // 在 ctrl 按下以及鼠标未按下的时候都不触发
            if (
              e.target.typeName &&
              e.target.typeName == 'controlRect' &&
              !this.keyboard.isCtrlDown
            ) {
              let obj = this.hasGenControlRectList.find(
                (item) => item.item_id == e.target.item_id
              )
              obj?.set({
                top: e.target.top + 1,
                left: e.target.left + 1,
              })
              this[canvasIdList[i]].bringToFront(obj)
              const w = e.target.width * e.target.scaleX
              const h = e.target.height * e.target.scaleY
              e.target.set({
                width: w,
                height: h,
                scaleX: 1,
                scaleY: 1,
              })

              // 是否为缩放操作
              if (e.transform && e.transform.action.includes('scale')) {
                obj.rectWidth = w // 外框宽度
                obj.rectHeight = h // 外框高度
                const { text, fontSize } = dealTextStr(
                  obj.text,
                  obj.fontSize,
                  obj.rectWidth,
                  obj.rectHeight,
                  obj.isAutoFontSize ?? this.isAutoFontSize
                )
                obj.text = text
                if (obj.isAutoFontSize) {
                  obj.fontSize = fontSize
                  obj.fontPt = Math.ceil(PxToPt(fontSize))
                  this.nowFontSize = Math.ceil(PxToPt(fontSize))
                }
              }
              obj?.setCoords()
              this.currentItem = obj
              this[canvasIdList[i]].renderAll()
            }
            if (
              e.target.typeName &&
              ['DataSource'].includes(e.target.typeName) &&
              this.currentItem
            ) {
              let obj = this.controlRects.find(
                (item) => item.item_id == e.target.item_id
              )
              if (obj) {
                obj.set({
                  top: e.target.top - 1,
                  left: e.target.left - 1,
                })
                this[canvasIdList[i]].renderAll()
              }
              // 不懂！
              // e.target.set({
              //   left: e.target.left,
              //   top: e.target.top,
              // })
            }
            if (
              e.target.typeName &&
              e.target.typeName == 'Onecode' &&
              e.transform &&
              e.transform.action.includes('scale')
            ) {
              const w = e.target.width * e.target.scaleX
              const h = e.target.height * e.target.scaleY
              e.target.set({
                mWidth: pxTomm(w, this.arrDPI),
                mHeight: pxTomm(h, this.arrDPI),
                Xscale: e.target.scaleX,
                Yscale: e.target.scaleY,
              })
              this[canvasIdList[i]].renderAll()
            }
            if (this.nowETarget.typeName == 'holeSlotPicture') {
              const current = this.nowETarget
              current.auxiliaryEl?.forEach((klass) => {
                klass.moveF(current)
              })
            }
          } else {
            this.isDraw = false
          }
        },
        'mouse:up': (e) => {
          if (e.target) {
            this[canvasIdList[i]].renderAll()
            if (e.target.typeName == 'Onecode') {
              this.codeH = e.target.mHeight
              this.codeW = e.target.mWidth
            }
          }
          isMove = false
          const page = this[canvasIdList[i]]
            .getObjects()
            .find((item) => item.typeName == 'TageBox')
          this[canvasIdList[i]].sendToBack(page)
          this[canvasIdList[i]].renderAll()
          if (e.target && e.target.typeName == 'controlRect') {
            this.dataSourceWidth = e.target.width.toFixed(2)
            this.dataSourceHeight = e.target.height.toFixed(2)
          }
          /** 多选控制 */
          if (this.keyboard.isCtrlDown && e.button === 1) {
            this.getCurrentUseSelectedControl()?.addObjectToSelected(e.target)
          }
        },
        'mouse:dblclick': (e) => {
          this.calcActiveCount(this.currentCanvas)
          if (e.target && e.target.typeName == 'TageBox' && !this.isAllSelect) {
            this[canvasIdList[i]].discardActiveObject()
            var sel = new fabric.ActiveSelection(
              this[canvasIdList[i]]
                .getObjects()
                .filter((it) => !['TageBox'].includes(it.typeName)),
              {
                canvas: this[canvasIdList[i]],
              }
            )
            this[canvasIdList[i]].setActiveObject(sel)
            sel.set({
              hasControls: false,
            })
            this.currentCanvas.requestRenderAll()
            this.isAllSelect = true
          } else if (!e.target) {
            this.currentCanvas.discardActiveObject()
            this.currentCanvas.requestRenderAll()
            this.isAllSelect = false
          } else if (e.target.typeName == 'DataSource') {
            this.$message({
              type: 'warning',
              message: '请在右侧属性栏选择数据信息',
              duration: 1500,
              offset: 100,
            })
          }
        },
        'mouse:down': (e) => {
          this.calcActiveCount(this.currentCanvas)
          this.mouseInCanvas = true
          this.isDraw = false
          if (e.target) {
            isMove = true
            let obj = e.target
            let active = mycanvas.getActiveObject() // 获取画布上的活动对象
            if (active) {
              this.isShowReset = e.target.source_data != 'plank_remarks'
              // this.mouse_wheel ? this.currentDelList.push(active) : []
              if (active.source_data == 'plankSignBox') {
                let sign = this.plankSignBox.find(
                  (item) => item.item_id == active.item_id
                )
                active = sign
                obj = sign
              }
              if (active.typeName == 'controlRect') {
                this.currentItem = this.hasGenControlRectList.find(
                  (item) => item.item_id == active.item_id
                )
                if (this.currentItem) {
                  this.dataSourceWidth = this.currentItem.rectWidth
                    ? Number(this.currentItem.rectWidth).toFixed(2)
                    : (this.currentItem.width + 2).toFixed(2)
                  this.dataSourceHeight = this.currentItem.rectHeight
                    ? Number(this.currentItem.rectHeight).toFixed(2)
                    : (this.currentItem.height + 2).toFixed(2)
                }
              } else {
                this.clearControlRect()
                this.currentItem = active
              }
              if (['DataSource'].includes(e.target.typeName) && e.target) {
                this.isAutoFontSize = e.target.isAutoFontSize ?? true
                this.dataSourceWidth = e.target.rectWidth
                  ? Number(e.target.rectWidth).toFixed(2)
                  : (e.target.width + 2).toFixed(2)
                this.dataSourceHeight = e.target.rectHeight
                  ? Number(e.target.rectHeight).toFixed(2)
                  : (e.target.height + 2).toFixed(2)
                this.isAutoFontSize = e.target.isAutoFontSize ?? true
                this.clearControlRect()
                this.genControlRect(e.target)
              }
              if (e.target.typeName == 'Onecode') {
                this.codeH = e.target.mHeight
                this.codeW = e.target.mWidth
                this.codeRotateDeg = e.target.rotateDeg ?? 0
              }
              if (e.target.typeName == 'Typography') {
                this.rotateDeg = e.target.rotateDeg ?? 0
              }

              if (e.target.typeName === 'specialMark') {
                // 需要使用存储的透明度，因为在元素点击之前就会将透明度设置为 0 以便完成吸附等操作
                this.inputOpacity = e.target._afterOpacity
                this.partSignValue = 'specialMark'
              }
              if (!this.mouse_wheel) {
                this.getKclassValueToAttr(this.currentItem)
              }
            } else {
              this.currentItem = null
            }
          } else {
            this.isAllSelect = false
          }
          if (!e.target || !e.target.item_id) {
            this.clearControlRect()
          }
        },
      })
    }
  },

  destroyed() {
    window.removeEventListener('mousedown', this.listenerMousedown, false)
    window.removeEventListener('mousemove', this.listenerMousemove, false)
    window.removeEventListener('mouseup', this.listenerMouseup, false)
    window.removeEventListener('keydown', this.listenerKeydown, false)
    window.removeEventListener('mousewheel', this.listenerMousewheel, false)
    canvasIdList.forEach((id) => {
      const canvas = this[id]
      canvas.off()
      this.getCurrentUseSelectedControl(canvas)?.removeAll()
    })
    // 清除控制器的引用
    fabricControlList.length = 0
    fabricMovingControlList.length = 0
  },
  computed: {
    ...mapState(['thinkerxMaterialKeys']),
    /** 返回当前使用的 fabric canvas 对象 */
    currentCanvas() {
      return this[this.mycanvasId]
    },
    menuStyle() {
      return 'display:flex;justify-content: space-between;padding:9px 0;width:180px;border-bottom:1px solid #E5E5E5;'
    },
    btnStyle() {
      return 'display:inline-lock;padding:0 3px;border:1px solid #DDDDDD;border-radius: 2px; font-size:12px'
    },
    // 计算放大倍数
    calcZoom() {
      return ((this.zoomNum / 1) * 100).toFixed(0) + '%'
    },
    attItemShow() {
      const currentArr = [...new Set(this.nowChooseSet.split(','))]
      return (data) => {
        // 始终求交集
        const dataKeys = data.split(',')
        const result = currentArr.every((k) => dataKeys.includes(k))
        return result
      }
    },
    nowDataSources() {
      // 需要判断数据是小板标签模板还是余料标签模板
      if (this.nowChooseSet == 'DataSource' && this.labelTemplates == 'plank') {
        let arr = [...this.dataSources.fieldData, ...this.thinkerxMaterialKeys]
        arr = Object.values(
          arr.reduce((obj, item) => ({ ...obj, [item.value]: item }), {})
        )
        arr = arr.map((item) => {
          if (item.value == 'plankSize') {
            return { ...item, text: '2440*1220*18' }
          } else return item
        })
        return arr
      } else if (
        this.nowChooseSet == 'DataSource' &&
        this.labelTemplates == 'surplus'
      ) {
        // 余料标签数据的处理
        return this.dataSources.surplusFieldData
      } else if (
        this.nowChooseSet == 'QRcode' &&
        this.labelTemplates == 'plank'
      ) {
        return this.dataSources.codeData
      } else if (
        this.nowChooseSet == 'QRcode' &&
        this.labelTemplates == 'surplus'
      ) {
        return this.dataSources.surplusCodeList
      } else if (
        this.nowChooseSet == 'Onecode' &&
        this.labelTemplates == 'plank'
      ) {
        return this.dataSources.onCodeData
      } else if (
        this.nowChooseSet == 'Onecode' &&
        this.labelTemplates == 'surplus'
      ) {
        return this.dataSources.suplusOnCodeList
      } else {
        return ''
      }
    },
    // 需要判断当前所在的标签模板
    groupDataSource() {
      let bluenDataList =
        this.labelTemplates == 'plank'
          ? this.dataSources.fieldData
          : this.dataSources.surplusFieldData
      let thirdDataList = this.thinkerxMaterialKeys
      let arr = [
        {
          label: '云排版数据',
          options: bluenDataList,
        },
      ]
      if (thirdDataList.length && this.labelTemplates == 'plank') {
        arr.push({
          label: '第三方数据',
          options: thirdDataList,
        })
      }
      return arr
    },
  },
}
</script>

<style lang="less">
//at.alicdn.com/t/font_2721570_5lfysiyv55s.css
.edit_tagTem_wapper {
  min-height: calc(100vh - 60px);
  .topBtn {
    display: flex;
    > div {
      width: 110px;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      background: #fafafa;
      border: 1px solid #dddddd;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 8px;
      > span {
        display: inline-block;
        margin-left: 4px;
      }
      &.funBtn {
        &:hover {
          border: 1px solid #18a8c7;
          color: #18a8c7;
        }
      }
      &.save {
        background-color: #18a8c7;
        color: #fff;
        border: 1px solid #18a8c7;
      }
    }
  }
  .com_content {
    // margin-top: 30px;
    display: flex;
    width: 100%;
    position: relative;
    .classTitle {
      display: flex;
      align-items: center;
      .title {
        display: inline-block;
        margin-right: 5px;
        font-size: 14px;
      }
      .dian {
        display: inline-block;
        margin-right: 10px;
        width: 6px;
        background-color: #999;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }

      font-weight: bold;
      color: #999;
    }
    .hint {
      padding: 0 0 14px;
      color: #999999;
    }
    .edit_tagTem_left {
      width: 316px;
      height: calc(100vh - 172px);
      padding: 10px 10px 20px 24px;
      background-color: #fff;
      font-size: 14px;
      .temp-switch {
        line-height: 36px;
        justify-content: space-around;
        margin-bottom: 10px;
        .tab-nav {
          width: 50%;
        }
        // 选中背景颜色
        .tab-background {
          background-color: #eee;
        }
      }
      > div {
        user-select: none;
        &.tagTemBox_info {
          > div {
            display: flex;
            align-items: center;
            padding: 10px 0;

            &.tagTem_size {
            }
          }
        }
        &.tagTem_set {
          .tagTem_setItem_list {
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;
            .img-class {
              width: 16px;
              height: 16px;
            }
            .color-cover {
              filter: drop-shadow(10, 10, #fff);
            }
            > div {
              margin-bottom: 20px;
              width: 120px;
              display: flex;
              // flex-flow: column;
              background-color: #f6f6f6;
              align-items: center;
              padding: 10px 0px 10px 24px;
              cursor: pointer;
              border-radius: 4px;
              &.active {
                background-color: #18a8c7;
                color: #fff;
              }
              &:nth-child(2n) {
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
    .edit_tagTem_mid {
      width: calc(100% - 316px - 316px);
      height: 700px;
      height: calc(100vh - 172px);
      overflow-y: hidden;
      position: relative;
      background-color: #eee;
      .hintMessage {
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 40px;
        background-color: #e6f7ff;
        border: 1px solid #91d5ff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 17px;
        > div {
          display: flex;
          align-items: center;
          > span {
            display: inline-block;
            margin-left: 8px;
          }
        }
        > span {
          cursor: pointer;
        }
      }
      canvas {
        width: 100%;
        height: 100%;
      }
      .operate_wap {
        font-size: 14px;
        user-select: none;
        position: absolute;
        bottom: 10px;
        right: 20px;
        display: flex;
        align-items: center;
        > div {
          color: #999999;
          .icon_btn {
            cursor: pointer;
            &:hover {
              color: #333;
            }
          }
          &.operate_menu {
            margin: 0 10px;
          }
        }
      }
    }
    .edit_tagTem_right {
      height: calc(100vh - 172px);
      position: absolute;
      right: 0;
      top: 0;
      width: 316px;
      background: #eeeeee;
      > div {
        padding: 10px 10px 20px 24px;
        background: #fff;
      }
      .attBox {
        // height: 400px;
        > div {
          > div {
            display: flex;
            flex: 4;
            align-items: center;
            padding: 10px 0;
            .att-name {
              display: inline-block;
              margin-right: 6px;
              min-width: 70px;
              max-width: 120px;
              overflow: hidden;
              text-overflow: ellipsis;
              text-wrap: nowrap;
            }
            .el-slider {
              width: 110px;
              margin: 0 5px;
            }
          }
        }
        .ncname-remark {
          margin-left: 78px;
          margin-top: -5px;
          color: #f00;
        }

        .error-info {
          top: 45px;
          left: 80px;
          color: #fd3232;
        }
      }
      .delete_reset {
        margin-top: 4px;
        height: calc(100% - 406px);
        > div {
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-wrap: wrap;
          .ant-btn {
            width: 125px;
            margin-bottom: 22px;
            background-color: #fafafa;
          }
        }
      }
    }
  }
  .statistics-box {
    display: block !important;
  }
}
</style>
