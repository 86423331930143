/** 其他操作选项 */
export const otherOperate = [
  {
    text: 'printPage.leftAlign',
    type: 'leftAlign',
    isDisable(num: number) {
      return num <= 1
    },
  },
  {
    text: 'printPage.rightAlign',
    type: 'rightAlign',
    isDisable(num: number) {
      return num <= 1
    },
  },
  {
    text: 'printPage.topAlign',
    type: 'topAlign',
    isDisable(num: number) {
      return num <= 1
    },
  },
  {
    text: 'printPage.bottomAlign',
    type: 'bottomAlign',
    isDisable(num: number) {
      return num <= 1
    },
  },
  {
    text: 'printPage.horizontalAlign',
    type: 'horizontalAlign',
    isDisable(num: number) {
      return num <= 1
    },
  },
  {
    text: 'printPage.verticalAlign',
    type: 'verticalAlign',
    isDisable(num: number) {
      return num <= 1
    },
  },
  {
    text: 'printPage.horizontalSplit',
    type: 'horizontalSplit',
    isDisable(num: number) {
      return num <= 2
    },
  },
  {
    text: 'printPage.verticalSplit',
    type: 'verticalSplit',
    isDisable(num: number) {
      return num <= 2
    },
  },
]
